import * as types from "../constants/action-types";
import * as tasks from "../constants/task-types";
import { encryptPassword } from "../util/authentication";
import { doAuthorization, doTestSession, doGetLanguage, doAuthorizationWithToken } from "../util/api";
import { setTestSessionFromXML } from "./deserializer";
import { parseXml } from "../util/xml";
import { removeReport } from "./reports";
import { doSubmitReport } from "../util/api";
import { getQueryStringParam } from "./../util/browser-support";
import language from "../constants/language";

export * from "./questionnaire";
export * from "./deserializer";
export * from "./tasks";
export * from "./cog-test";
export * from "./reports";

const Sentry = window.Sentry;

export const setUsername = (username) => ({
  type: types.SET_USERNAME,
  username,
});
export const setPassword = (password) => ({
  type: types.SET_PASSWORD,
  password,
});
export const setAuthenticating = (authenticating) => ({
  type: types.SET_AUTHENTICATING,
  authenticating,
});
export const setUserToken = (userToken) => ({
  type: types.SET_USER_TOKEN,
  userToken
})

const taskNameToConst = (name) => {
  switch (name) {
    case "questionnaire":
      return tasks.QUESTIONNAIRE_TASK;
    case "word":
      return tasks.WORD_TASK;
    case "time":
      return tasks.TIME_TASK;
    case "emotion_identification":
      return tasks.EMOTION_IDENTIFICATION_TASK;
    case "tapping":
      return tasks.TAPPING_TASK;
    case "one_back":
      return tasks.ONE_BACK_TASK;
    case "stop_or_go":
      return tasks.STOP_OR_GO_TASK;
    case "sentence":
      return tasks.SENTENCE_TASK;
    case "facial_recognition":
      return tasks.FACIAL_RECOGNITION_TASK;
    default:
      throw new Error(`Unknown task type: ${name}`);
  }
};

export const setTrialVersion = (trialVersion = true) => ({
  type: types.SET_TRIAL_VERSION,
  trialVersion,
});

export const setAuthenticated = (authenticated = true) => ({
  type: types.SET_AUTHENTICATED,
  authenticated,
});

export const setRetrievingTestSession = (retrievingTestSession) => ({
  type: types.SET_RETRIEVING_TEST_SESSION,
  retrievingTestSession,
});

// get the test session from the server
export const retrieveTestSession = () => (dispatch, getState) => {
  const { username, password } = getState().authentication;
  dispatch(setRetrievingTestSession(true));
  doTestSession(username, password)
    .then((r) => r.text())
    .then((xml) => dispatch(setTestSessionFromXML(xml)))
    .then(() => dispatch(setRetrievingTestSession(false)))
    .catch((e) => alert(e));
};

export const login = () => (dispatch, getState) => {
  const { reports, authentication: { username, password, userToken } } = getState();
  dispatch(setAuthenticating(true));
  submitReports(dispatch, reports, username, userToken)
    .then(() => {
      const authorizationPromise = userToken
        ? doAuthorizationWithToken(userToken)
        : doAuthorization(username, encryptPassword(password));
      return authorizationPromise;
    })
    .then(response => response.text())
    .then(parseXml)
    .then(({ auth: { $: result } }) => {
      handleAuthorizationResponse(dispatch, result);
      return postLoginTasks(dispatch);
    })
    .catch(error => {
      alert(error);
    })
    .finally(() => {
      dispatch(setAuthenticating(false));
      window.history.replaceState(null, '', window.location.pathname);
    });
};

const submitReports = (dispatch, reports, username, userToken) => {
  const reportPromises = Object.keys(reports).map(reference => {
    return Promise.resolve(true);
    return doSubmitReport(username, reference, reports[reference])
      .then(() => dispatch(removeReport(reference)))
      .catch(error => {
        Sentry.captureException(error);
        dispatch(removeReport(reference));
      });
  });

  return Promise.all(reportPromises);
};

const handleAuthorizationResponse = (dispatch, result) => {
  switch (result.status) {
    case 'SERVER_ERROR':
      throw new Error(result.errormessage);
    case 'SUCCESS':
      dispatch(setUsername(result.login));
      dispatch(setPassword(result.password));
      break;
    default:
      dispatch(setUsername(''));
      dispatch(setPassword(''));
      throw new Error(result.errormessage);
  }
};

const postLoginTasks = (dispatch) => {
  dispatch(setAuthenticated());
  return dispatch(retrieveTestSession());
};

// the user has seen and continued on from the landing instruction page
export const setPreLoginShown = (preLoginShown = true) => ({
  type: types.SET_PRE_LOGIN_SHOWN,
  preLoginShown,
});

// the user has seen and continued on from the landing instruction page
export const setLandingShown = (landingShown = true) => ({
  type: types.SET_LANDING_SHOWN,
  landingShown,
});

export const retrieveLanguage = () => (dispatch, getState) => {
  try {
    const lanParam = getQueryStringParam("lan", "en")
    const languageStrings = language(lanParam);
    dispatch({
      type: types.SET_LANGUAGE,
      data: languageStrings,
    });
    dispatch(setPreLoginShown());
    return Promise.resolve("SUCCESS");
  } catch (error) {
    Sentry.captureMessage("XLNTBrain language constants could not be loaded for: " + lanParam);
    return Promise.reject(error.message);
  }
};

// display the "Task Completed…" message between tasks
// If the message is to be displayed, nextAction is the action to execute after a delay.
export const setShowTaskCompletion =
  (showTaskCompletion = true, nextAction = undefined) =>
  (dispatch) => {
    if (showTaskCompletion) {
      setTimeout(() => dispatch(nextAction), 3000);
    }
    dispatch({ type: types.SET_SHOW_TASK_COMPLETION, showTaskCompletion });
  };

export const restart = () => ({ type: types.RESTART });
