const language = (lanQueryParam) => {
  const en_strings = {
    _id: ["57ec19dba49ef6872b000002"],
    "created-at": [{ _: "2016-09-28T14:28:27-05:00", $: { type: "dateTime" } }],
    "creator-id": ["4f7a97fc72e2720007000002"],
    "email-label": ["E-mail"],
    "emotion-afraid": ["afraid"],
    "emotion-afraid-instruction-text": ["afraid"],
    "emotion-angry": ["angry"],
    "emotion-angry-instruction-text": ["angry"],
    "emotion-happy": ["happy"],
    "emotion-happy-instruction-text": ["happy"],
    "emotion-identification-instructions-message-1": [
      "In this test, a series of images of faces expressing emotions will be presented one at a time.",
    ],
    "emotion-identification-instructions-message-2": [
      "Tap the spacebar <strong>ONCE</strong> as quickly as possible after each photograph",
    ],
    "emotion-identification-instructions-message-3": [
      '<strong>*EXCEPT*</strong> for the "[[emotion]]" faces.',
    ],
    "emotion-identification-instructions-message-4": [
      "Hit the spacebar to begin the task...",
    ],
    "emotion-identification-title": [
      "Emotion Identification (Except [[emotion]])",
    ],
    "emotion-neutral": ["neutral"],
    "emotion-neutral-instruction-text": ["neutral"],
    "emotion-sad": ["sad"],
    "emotion-sad-instruction-text": ["sad"],
    "facial-recognition-image-text": ["This is the person I've seen before"],
    "facial-recognition-instructions-message-1": [
      "In this test, pairs of photographs of faces will be presented.",
    ],
    "facial-recognition-instructions-message-2": [
      "Tap the <strong>right</strong> arrow key <strong>ONCE</strong> if you recognize the face on the <strong>right</strong> from earlier in the test battery.",
    ],
    "facial-recognition-instructions-message-3": [
      "Tap the <strong>left</strong> arrow key <strong>ONCE</strong> if you recognize the face on the <strong>left</strong> from earlier in the test battery.",
    ],
    "facial-recognition-instructions-message-4": [
      "Tap the <strong>right</strong> arrow key once to begin.",
    ],
    "facial-recognition-title": ["Facial Recognition"],
    "finished-test-message": ["You have finished the XLNTbrain-Cog Test."],
    "go-to-sign-in-button-label": ["Go to Sign In Page"],
    "landing-page-message-1": [
      "You are about to begin the XLNTbrain-Cog Test.",
    ],
    "landing-page-message-2": [
      "This test is used to test YOUR performance. Do not get help from someone else except to understand the instructions.",
    ],
    "landing-page-message-3": [
      "Read the instructions in each test section carefully before starting.",
    ],
    "landing-page-message-4": [
      "This test will take approximately 35 minutes. Please allow enough time to complete the test.",
    ],
    "landing-page-message-5": [
      "Before you start the test, make sure your environment is quiet and free from distractions such as phones, electronic devices and other people.",
    ],
    "language-key": ["en"],
    "log-report-button-label": ["Log Report"],
    "login-in-progress": ["Login in progress..."],
    "login-message-1": [
      "Use the same e-mail and password that you created for xlntbrain.com.",
    ],
    "login-message-2": [
      "Close all other running programs on your computer before you start your test.",
    ],
    "next-step-button-label": ["Next Step"],
    "next-task-button-label": ["Next Task"],
    "one-back-instructions-message-1": [
      "In this test, a series of photographs will be presented one at a time.",
    ],
    "one-back-instructions-message-2": [
      "Tap the spacebar <strong>ONCE</strong> after each new photograph.",
    ],
    "one-back-instructions-message-3": [
      "Do not tap the spacebar if the photograph is the same as the one presented before it.",
    ],
    "one-back-instructions-message-4": ["Tap the spacebar once to begin."],
    "one-back-title": ["One Back"],
    "password-label": ["Password"],
    "questionnaire-button-next": ["Next"],
    "questionnaire-button-no": ["No"],
    "questionnaire-button-previous": ["Previous"],
    "questionnaire-button-yes": ["Yes"],
    "questionnaire-instructions-message-1": [
      "Please tell us how often you have experienced the following symptoms in the last 30 days. If you are taking this test after suffering a potential concussion, then report the symptoms that you have been experiencing over the last 24 hours. Try to be as accurate as possible.",
    ],
    "questionnaire-instructions-message-2": [
      "Hit the spacebar to begin the task...",
    ],
    "questionnaire-option-never": ["Never"],
    "questionnaire-option-often": ["Often"],
    "questionnaire-option-sometimes": ["Sometimes"],
    "questionnaire-option-very-often": ["Very Often"],
    "questionnaire-question-1": ["I have been having headaches."],
    "questionnaire-question-10": ["I have been sleeping too much."],
    "questionnaire-question-11": ["I have been worrying."],
    "questionnaire-question-12": [
      "I have had problems finding the right words.",
    ],
    "questionnaire-question-13": ["I have felt nauseated."],
    "questionnaire-question-14": ["I have felt angry."],
    "questionnaire-question-15": ["I have felt sad."],
    "questionnaire-question-16": ["I have not been sleeping enough."],
    "questionnaire-question-17": ["I have felt irritable."],
    "questionnaire-question-18": ["I have been sensitive to light or sound."],
    "questionnaire-question-19": [
      "I have been yelling at people when I am mad.",
    ],
    "questionnaire-question-2": ["I have felt mentally foggy."],
    "questionnaire-question-20": ["I have had trouble falling asleep."],
    "questionnaire-question-21": ["I have been falling."],
    "questionnaire-question-22": [
      "My thoughts have been racing as I try to fall asleep.",
    ],
    "questionnaire-question-23": ["I have having trouble remembering things."],
    "questionnaire-question-24": ["I have been struggling to solve problems."],
    "questionnaire-question-25": ["I have felt off balance."],
    "questionnaire-question-26": ["I have felt tired."],
    "questionnaire-question-27": ["I have felt helpless or hopeless."],
    "questionnaire-question-28": ["I have felt dizzy."],
    "questionnaire-question-29": ["I have been uncoordinated."],
    "questionnaire-question-3": ["I have felt nervous."],
    "questionnaire-question-30": ["I have been crying a lot."],
    "questionnaire-question-31": ["I have felt my heartbeat racing."],
    "questionnaire-question-32": [
      "I have felt afraid or like something bad is about to happen.",
    ],
    "questionnaire-question-33": [
      "I have been losing control when I am angry.",
    ],
    "questionnaire-question-34": ["I have been fighting a lot."],
    "questionnaire-question-35": ["I have been clumsy."],
    "questionnaire-question-36": ["Are you taking any medication?"],
    "questionnaire-question-4": ["I have had blurred vision."],
    "questionnaire-question-5": [
      "I have been throwing or breaking things when I am mad.",
    ],
    "questionnaire-question-6": ["I have been having trouble staying focused."],
    "questionnaire-question-7": ["I have been vomiting."],
    "questionnaire-question-8": ["I have been too sleepy during the day."],
    "questionnaire-question-9": ["I have not felt like doing anything."],
    "questionnaire-title": ["Symptom Checklist"],
    "report-has-been-sent-message": ["Report has been sent!"],
    "sending-report-message": ["Sending report. Please wait..."],
    "sentence-instructions-animation-sentence": ["This is the grand total."],
    "sentence-instructions-make-sense": ["Makes sense..."],
    "sentence-instructions-message-1": [
      "In this test, a series of 5 word sentences will be presented one word at a time.",
    ],
    "sentence-instructions-message-2": [
      "Tap the <strong>right</strong> arrow key <strong>ONCE</strong> if the last word of each sentence makes sense.",
    ],
    "sentence-instructions-message-3": [
      "Tap the <strong>left</strong> arrow key <strong>ONCE</strong> if the last word of each sentence does not make sense.",
    ],
    "sentence-instructions-message-4": [
      "Respond as quickly and accurately as possible.",
    ],
    "sentence-instructions-message-5": [
      "Tap the <strong>right</strong> arrow key once to begin.",
    ],
    "sentence-instructions-not-make-sense": ["Does Not Make sense..."],
    "sentence-list-a-bird-can-fly-firemen": ["A bird can fly firemen."],
    "sentence-list-a-bird-can-fly-high": ["A bird can fly high."],
    "sentence-list-a-bug-crawled-on-me": ["A bug crawled on me."],
    "sentence-list-a-bug-crawled-on-midnight": ["A bug crawled on midnight."],
    "sentence-list-a-cowboy-can-rope-cattle": ["A cowboy can rope cattle."],
    "sentence-list-a-cowboy-can-rope-equator": ["A cowboy can rope equator."],
    "sentence-list-a-cube-has-six-playpens": ["A cube has six playpens."],
    "sentence-list-a-cube-has-six-sides": ["A cube has six sides."],
    "sentence-list-a-day-is-twentyfour-hours": ["A day is twenty-four hours."],
    "sentence-list-a-day-is-twentyfour-oranges": [
      "A day is twenty-four oranges.",
    ],
    "sentence-list-a-dragonfly-is-a-bug": ["A dragonfly is a bug."],
    "sentence-list-a-dragonfly-is-a-scaffold": ["A dragonfly is a scaffold."],
    "sentence-list-a-green-light-means-crown": ["A green light means crown."],
    "sentence-list-a-green-light-means-go": ["A green light means go."],
    "sentence-list-a-jellyfish-has-long-burglar": [
      "A jellyfish has long burglar.",
    ],
    "sentence-list-a-jellyfish-has-long-tentacles": [
      "A jellyfish has long tentacles.",
    ],
    "sentence-list-a-lifeguard-rescued-a-swimmer": [
      "A lifeguard rescued a swimmer.",
    ],
    "sentence-list-a-lifeguard-rescued-a-yesterday": [
      "A lifeguard rescued a yesterday.",
    ],
    "sentence-list-a-minute-is-sixty-ants": ["A minute is sixty ants."],
    "sentence-list-a-minute-is-sixty-seconds": ["A minute is sixty seconds."],
    "sentence-list-a-mountain-is-very-cough": ["A mountain is very cough."],
    "sentence-list-a-mountain-is-very-large": ["A mountain is very large."],
    "sentence-list-a-rattlesnake-shakes-its-holiday": [
      "A rattlesnake shakes its holiday.",
    ],
    "sentence-list-a-rattlesnake-shakes-its-tail": [
      "A rattlesnake shakes its tail.",
    ],
    "sentence-list-a-red-light-means-donkey": ["A red light means donkey."],
    "sentence-list-a-red-light-means-stop": ["A red light means stop."],
    "sentence-list-a-shamrock-is-a-clover": ["A shamrock is a clover."],
    "sentence-list-a-shamrock-is-a-sunk": ["A shamrock is a sunk."],
    "sentence-list-a-tombstone-marked-the-grave": [
      "A tombstone marked the grave.",
    ],
    "sentence-list-a-tombstone-marked-the-growl": [
      "A tombstone marked the growl.",
    ],
    "sentence-list-a-tree-blocked-the-calendar": [
      "A tree blocked the calendar.",
    ],
    "sentence-list-a-tree-blocked-the-path": ["A tree blocked the path."],
    "sentence-list-a-week-is-seven-days": ["A week is seven days."],
    "sentence-list-a-week-is-seven-hares": ["A week is seven hares."],
    "sentence-list-a-year-is-twelve-dimes": ["A year is twelve dimes."],
    "sentence-list-a-year-is-twelve-months": ["A year is twelve months."],
    "sentence-list-actions-speak-louder-than-plantation": [
      "Actions speak louder than plantation.",
    ],
    "sentence-list-actions-speak-louder-than-words": [
      "Actions speak louder than words.",
    ],
    "sentence-list-adding-numbers-gives-a-ladybug": [
      "Adding numbers gives a ladybug.",
    ],
    "sentence-list-adding-numbers-gives-a-total": [
      "Adding numbers gives a total.",
    ],
    "sentence-list-advance-warnings-are-safety-alerts": [
      "Advance warnings are safety alerts.",
    ],
    "sentence-list-advance-warnings-are-safety-lettuce": [
      "Advance warnings are safety lettuce.",
    ],
    "sentence-list-always-breathe-when-you-cucumber": [
      "Always breathe when you cucumber.",
    ],
    "sentence-list-always-breathe-when-you-exercise": [
      "Always breathe when you exercise.",
    ],
    "sentence-list-an-elephants-trunk-is-cobweb": [
      "An elephant's trunk is cobweb.",
    ],
    "sentence-list-an-elephants-trunk-is-long": [
      "An elephant's trunk is long.",
    ],
    "sentence-list-an-hour-is-sixty-minutes": ["An hour is sixty minutes."],
    "sentence-list-an-hour-is-sixty-umbrellas": ["An hour is sixty umbrellas."],
    "sentence-list-any-child-would-love-candy": ["Any child would love candy."],
    "sentence-list-any-child-would-love-scarce": [
      "Any child would love scarce.",
    ],
    "sentence-list-babies-are-born-in-hospitals": [
      "Babies are born in hospitals.",
    ],
    "sentence-list-babies-are-born-in-shoulder": [
      "Babies are born in shoulder.",
    ],
    "sentence-list-blue-is-my-favorite-color": ["Blue is my favorite color."],
    "sentence-list-blue-is-my-favorite-quest": ["Blue is my favorite quest."],
    "sentence-list-breakfast-is-my-favorite-lamp": [
      "Breakfast is my favorite lamp.",
    ],
    "sentence-list-breakfast-is-my-favorite-meal": [
      "Breakfast is my favorite meal.",
    ],
    "sentence-list-bring-your-lunch-to-ivory": ["Bring your lunch to ivory."],
    "sentence-list-bring-your-lunch-to-school": ["Bring your lunch to school."],
    "sentence-list-call-me-on-the-phone": ["Call me on the phone."],
    "sentence-list-call-me-on-the-squirrel": ["Call me on the squirrel."],
    "sentence-list-cars-travel-on-the-laugh": ["Cars travel on the laugh."],
    "sentence-list-cars-travel-on-the-road": ["Cars travel on the road."],
    "sentence-list-clowns-crammed-into-a-car": ["Clowns crammed into a car."],
    "sentence-list-clowns-crammed-into-a-knee": ["Clowns crammed into a knee."],
    "sentence-list-cookies-baked-in-the-chalkboard": [
      "Cookies baked in the chalkboard.",
    ],
    "sentence-list-cookies-baked-in-the-oven": ["Cookies baked in the oven."],
    "sentence-list-cut-cardboard-with-sharp-scissors": [
      "Cut cardboard with sharp scissors.",
    ],
    "sentence-list-cut-cardboard-with-sharp-trouble": [
      "Cut cardboard with sharp trouble.",
    ],
    "sentence-list-cut-meat-with-sharp-knives": ["Cut meat with sharp knives."],
    "sentence-list-cut-meat-with-sharp-skyline": [
      "Cut meat with sharp skyline.",
    ],
    "sentence-list-do-not-block-my-careless": ["Do not block my careless."],
    "sentence-list-do-not-block-my-path": ["Do not block my path."],
    "sentence-list-doctors-help-cure-sick-muffins": [
      "Doctors help cure sick muffins.",
    ],
    "sentence-list-doctors-help-cure-sick-patients": [
      "Doctors help cure sick patients.",
    ],
    "sentence-list-dogs-bark-at-other-animals": ["Dogs bark at other animals."],
    "sentence-list-dogs-bark-at-other-eggnog": ["Dogs bark at other eggnog."],
    "sentence-list-ducks-floated-on-the-pond": ["Ducks floated on the pond."],
    "sentence-list-ducks-floated-on-the-suntan": [
      "Ducks floated on the suntan.",
    ],
    "sentence-list-eyeglasses-help-me-see-clearly": [
      "Eyeglasses help me see clearly.",
    ],
    "sentence-list-eyeglasses-help-me-see-wrench": [
      "Eyeglasses help me see wrench.",
    ],
    "sentence-list-five-nickels-equal-one-hour": [
      "Five nickels equal one hour.",
    ],
    "sentence-list-five-nickels-equal-one-quarter": [
      "Five nickels equal one quarter.",
    ],
    "sentence-list-five-pennies-equal-one-nickel": [
      "Five pennies equal one nickel.",
    ],
    "sentence-list-five-pennies-equal-one-second": [
      "Five pennies equal one second.",
    ],
    "sentence-list-flamingos-stand-on-one-foot": [
      "Flamingos stand on one foot.",
    ],
    "sentence-list-flamingos-stand-on-one-stew": [
      "Flamingos stand on one stew.",
    ],
    "sentence-list-football-players-are-good-athletes": [
      "Football players are good athletes.",
    ],
    "sentence-list-football-players-are-good-rust": [
      "Football players are good rust.",
    ],
    "sentence-list-four-quarters-equal-one-dollar": [
      "Four quarters equal one dollar.",
    ],
    "sentence-list-four-quarters-equal-one-month": [
      "Four quarters equal one month.",
    ],
    "sentence-list-fruit-is-a-healthy-pencil": ["Fruit is a healthy pencil."],
    "sentence-list-fruit-is-a-healthy-snack": ["Fruit is a healthy snack."],
    "sentence-list-funny-jokes-make-me-airport": [
      "Funny jokes make me airport.",
    ],
    "sentence-list-funny-jokes-make-me-laugh": ["Funny jokes make me laugh."],
    "sentence-list-giraffes-have-very-long-footballs": [
      "Giraffes have very long footballs.",
    ],
    "sentence-list-giraffes-have-very-long-necks": [
      "Giraffes have very long necks.",
    ],
    "sentence-list-gray-is-darker-than-construction": [
      "Gray is darker than construction.",
    ],
    "sentence-list-gray-is-darker-than-white": ["Gray is darker than white."],
    "sentence-list-gray-is-lighter-than-black": ["Gray is lighter than black."],
    "sentence-list-gray-is-lighter-than-island": [
      "Gray is lighter than island.",
    ],
    "sentence-list-he-climbed-over-the-echo": ["He climbed over the echo."],
    "sentence-list-he-climbed-over-the-hill": ["He climbed over the hill."],
    "sentence-list-he-drew-a-straight-line": ["He drew a straight line."],
    "sentence-list-he-drew-a-straight-unhappy": ["He drew a straight unhappy."],
    "sentence-list-he-ran-like-the-book": ["He ran like the book."],
    "sentence-list-he-ran-like-the-wind": ["He ran like the wind."],
    "sentence-list-he-ran-toward-me-foundation": [
      "He ran toward me foundation.",
    ],
    "sentence-list-he-ran-toward-me-quickly": ["He ran toward me quickly."],
    "sentence-list-her-right-arm-was-broken": ["Her right arm was broken."],
    "sentence-list-her-right-arm-was-cemetery": ["Her right arm was cemetery."],
    "sentence-list-his-belt-was-too-tight": ["His belt was too tight."],
    "sentence-list-his-belt-was-too-waterfall": ["His belt was too waterfall."],
    "sentence-list-his-loud-voice-was-audible": ["His loud voice was audible."],
    "sentence-list-his-loud-voice-was-climb": ["His loud voice was climb."],
    "sentence-list-i-appreciate-eating-good-food": [
      "I appreciate eating good food.",
    ],
    "sentence-list-i-appreciate-eating-good-window": [
      "I appreciate eating good window.",
    ],
    "sentence-list-i-can-ride-my-fireplace": ["I can ride my fireplace."],
    "sentence-list-i-can-ride-my-scooter": ["I can ride my scooter."],
    "sentence-list-i-chat-on-the-cartwheel": ["I chat on the cartwheel."],
    "sentence-list-i-chat-on-the-telephone": ["I chat on the telephone."],
    "sentence-list-i-cry-when-i-cupboard": ["I cry when I cupboard."],
    "sentence-list-i-cry-when-i-fall": ["I cry when I fall."],
    "sentence-list-i-did-my-math-apartment": ["I did my math apartment."],
    "sentence-list-i-did-my-math-homework": ["I did my math homework."],
    "sentence-list-i-drink-milk-with-billboard": [
      "I drink milk with billboard.",
    ],
    "sentence-list-i-drink-milk-with-breakfast": [
      "I drink milk with breakfast.",
    ],
    "sentence-list-i-drink-through-a-straw": ["I drink through a straw."],
    "sentence-list-i-drink-through-a-toe": ["I drink through a toe."],
    "sentence-list-i-fell-down-the-hill": ["I fell down the hill."],
    "sentence-list-i-fell-down-the-notebook": ["I fell down the notebook."],
    "sentence-list-i-got-stuck-in-leaf": ["I got stuck in leaf."],
    "sentence-list-i-got-stuck-in-traffic": ["I got stuck in traffic."],
    "sentence-list-i-grew-two-inches-taller": ["I grew two inches taller."],
    "sentence-list-i-grew-two-inches-wilderness": [
      "I grew two inches wilderness.",
    ],
    "sentence-list-i-have-a-pet-dog": ["I have a pet dog."],
    "sentence-list-i-have-a-pet-pavilion": ["I have a pet pavilion."],
    "sentence-list-i-like-hot-beef-shoe": ["I like hot beef shoe."],
    "sentence-list-i-like-hot-beef-stew": ["I like hot beef stew."],
    "sentence-list-i-live-with-my-family": ["I live with my family."],
    "sentence-list-i-live-with-my-hoist": ["I live with my hoist."],
    "sentence-list-i-moved-ahead-in-lamp": ["I moved ahead in lamp."],
    "sentence-list-i-moved-ahead-in-line": ["I moved ahead in line."],
    "sentence-list-i-raced-in-my-pebble": ["I raced in my pebble."],
    "sentence-list-i-raced-in-my-sneakers": ["I raced in my sneakers."],
    "sentence-list-i-rode-my-bike-bacon": ["I rode my bike bacon."],
    "sentence-list-i-rode-my-bike-yesterday": ["I rode my bike yesterday."],
    "sentence-list-i-shiver-from-the-bucket": ["I shiver from the bucket."],
    "sentence-list-i-shiver-from-the-cold": ["I shiver from the cold."],
    "sentence-list-i-tore-the-paper-apart": ["I tore the paper apart."],
    "sentence-list-i-tore-the-paper-squirt": ["I tore the paper squirt."],
    "sentence-list-i-understand-the-words-meaning": [
      "I understand the word's meaning.",
    ],
    "sentence-list-i-understand-the-words-scarecrow": [
      "I understand the word's scarecrow.",
    ],
    "sentence-list-i-walk-on-the-roar": ["I walk on the roar."],
    "sentence-list-i-walk-on-the-sidewalk": ["I walk on the sidewalk."],
    "sentence-list-i-want-to-live-explode": ["I want to live explode."],
    "sentence-list-i-want-to-live-forever": ["I want to live forever."],
    "sentence-list-i-went-to-the-gone": ["I went to the gone."],
    "sentence-list-i-went-to-the-park": ["I went to the park."],
    "sentence-list-i-will-cook-scrambled-eggs": ["I will cook scrambled eggs."],
    "sentence-list-i-will-cook-scrambled-railway": [
      "I will cook scrambled railway.",
    ],
    "sentence-list-i-will-read-the-book": ["I will read the book."],
    "sentence-list-i-will-read-the-brush": ["I will read the brush."],
    "sentence-list-i-wore-a-gold-necklace": ["I wore a gold necklace."],
    "sentence-list-i-wore-a-gold-ocean": ["I wore a gold ocean."],
    "sentence-list-it-gets-dark-after-sister": ["It gets dark after sister."],
    "sentence-list-it-gets-dark-after-sunset": ["It gets dark after sunset."],
    "sentence-list-it-is-cold-in-directory": ["It is cold in directory."],
    "sentence-list-it-is-cold-in-winter": ["It is cold in winter."],
    "sentence-list-it-is-dark-at-night": ["It is dark at night."],
    "sentence-list-it-is-dark-at-seesaw": ["It is dark at seesaw."],
    "sentence-list-it-is-hot-in-homework": ["It is hot in homework."],
    "sentence-list-it-is-hot-in-summer": ["It is hot in summer."],
    "sentence-list-jack-ran-through-the-snug": ["Jack ran through the snug."],
    "sentence-list-jack-ran-through-the-yard": ["Jack ran through the yard."],
    "sentence-list-jane-is-a-fast-swimmer": ["Jane is a fast swimmer."],
    "sentence-list-jane-is-a-fast-tent": ["Jane is a fast tent."],
    "sentence-list-jump-to-get-the-hour": ["Jump to get the hour."],
    "sentence-list-jump-to-get-the-rebound": ["Jump to get the rebound."],
    "sentence-list-kickball-is-my-favorite-carburetor": [
      "Kickball is my favorite carburetor.",
    ],
    "sentence-list-kickball-is-my-favorite-game": [
      "Kickball is my favorite game.",
    ],
    "sentence-list-mountains-are-taller-than-hills": [
      "Mountains are taller than hills.",
    ],
    "sentence-list-mountains-are-taller-than-sleet": [
      "Mountains are taller than sleet.",
    ],
    "sentence-list-my-attack-dog-is-aggressive": [
      "My attack dog is aggressive.",
    ],
    "sentence-list-my-attack-dog-is-mailbox": ["My attack dog is mailbox."],
    "sentence-list-my-heart-is-beating-fast": ["My heart is beating fast."],
    "sentence-list-my-heart-is-beating-sidewalk": [
      "My heart is beating sidewalk.",
    ],
    "sentence-list-my-new-bike-arrived-necktie": [
      "My new bike arrived necktie.",
    ],
    "sentence-list-my-new-bike-arrived-today": ["My new bike arrived today."],
    "sentence-list-my-toothpaste-is-mint-flavored": [
      "My toothpaste is mint flavored.",
    ],
    "sentence-list-my-toothpaste-is-mint-woodchuck": [
      "My toothpaste is mint woodchuck.",
    ],
    "sentence-list-never-walk-alone-at-night": ["Never walk alone at night."],
    "sentence-list-never-walk-alone-at-peanut": ["Never walk alone at peanut."],
    "sentence-list-paintings-hung-on-the-noise": [
      "Paintings hung on the noise.",
    ],
    "sentence-list-paintings-hung-on-the-wall": ["Paintings hung on the wall."],
    "sentence-list-plan-ahead-to-prevent-delays": [
      "Plan ahead to prevent delays.",
    ],
    "sentence-list-plan-ahead-to-prevent-helmet": [
      "Plan ahead to prevent helmet.",
    ],
    "sentence-list-planes-land-at-the-airport": ["Planes land at the airport."],
    "sentence-list-planes-land-at-the-frugal": ["Planes land at the frugal."],
    "sentence-list-planets-move-around-the-stocking": [
      "Planets move around the stocking.",
    ],
    "sentence-list-planets-move-around-the-sun": [
      "Planets move around the sun.",
    ],
    "sentence-list-players-sat-on-the-sideline": [
      "Players sat on the sideline.",
    ],
    "sentence-list-players-sat-on-the-subtraction": [
      "Players sat on the subtraction.",
    ],
    "sentence-list-rhinos-have-a-bony-doghouse": [
      "Rhinos have a bony doghouse.",
    ],
    "sentence-list-rhinos-have-a-bony-horn": ["Rhinos have a bony horn."],
    "sentence-list-riding-a-rollercoaster-is-jellyfish": [
      "Riding a rollercoaster is jellyfish.",
    ],
    "sentence-list-riding-a-rollercoaster-is-thrilling": [
      "Riding a rollercoaster is thrilling.",
    ],
    "sentence-list-rockets-launched-up-into-pancake": [
      "Rockets launched up into pancake.",
    ],
    "sentence-list-rockets-launched-up-into-space": [
      "Rockets launched up into space.",
    ],
    "sentence-list-rocks-are-heavier-than-circus": [
      "Rocks are heavier than circus.",
    ],
    "sentence-list-rocks-are-heavier-than-feathers": [
      "Rocks are heavier than feathers.",
    ],
    "sentence-list-runners-jumped-over-the-hurdle": [
      "Runners jumped over the hurdle.",
    ],
    "sentence-list-runners-jumped-over-the-outside": [
      "Runners jumped over the outside.",
    ],
    "sentence-list-seagulls-live-by-the-crouton": [
      "Seagulls live by the crouton.",
    ],
    "sentence-list-seagulls-live-by-the-sea": ["Seagulls live by the sea."],
    "sentence-list-she-bent-over-to-birthday": ["She bent over to birthday."],
    "sentence-list-she-bent-over-to-stretch": ["She bent over to stretch."],
    "sentence-list-she-slipped-and-fell-boxcar": [
      "She slipped and fell boxcar.",
    ],
    "sentence-list-she-slipped-and-fell-down": ["She slipped and fell down."],
    "sentence-list-ships-sail-across-the-juggle": [
      "Ships sail across the juggle.",
    ],
    "sentence-list-ships-sail-across-the-sea": ["Ships sail across the sea."],
    "sentence-list-shrimp-is-my-favorite-impatient": [
      "Shrimp is my favorite impatient.",
    ],
    "sentence-list-shrimp-is-my-favorite-seafood": [
      "Shrimp is my favorite seafood.",
    ],
    "sentence-list-tables-are-flat-on-bluebird": [
      "Tables are flat on bluebird.",
    ],
    "sentence-list-tables-are-flat-on-top": ["Tables are flat on top."],
    "sentence-list-ten-dimes-equal-one-day": ["Ten dimes equal one day."],
    "sentence-list-ten-dimes-equal-one-dollar": ["Ten dimes equal one dollar."],
    "sentence-list-the-baby-slept-in-peace": ["The baby slept in peace."],
    "sentence-list-the-baby-slept-in-toothbrush": [
      "The baby slept in toothbrush.",
    ],
    "sentence-list-the-baby-weighed-seven-overrule": [
      "The baby weighed seven overrule.",
    ],
    "sentence-list-the-baby-weighed-seven-pounds": [
      "The baby weighed seven pounds.",
    ],
    "sentence-list-the-bandaid-healed-my-carrot": [
      "The band-aid healed my carrot.",
    ],
    "sentence-list-the-bandaid-healed-my-cut": ["The band-aid healed my cut."],
    "sentence-list-the-battle-lasted-many-skates": [
      "The battle lasted many skates.",
    ],
    "sentence-list-the-battle-lasted-many-years": [
      "The battle lasted many years.",
    ],
    "sentence-list-the-big-bee-stung-me": ["The big bee stung me."],
    "sentence-list-the-big-bee-stung-rainbow": ["The big bee stung rainbow."],
    "sentence-list-the-blue-bird-flew-away": ["The blue bird flew away."],
    "sentence-list-the-blue-bird-flew-toaster": ["The blue bird flew toaster."],
    "sentence-list-the-book-was-a-long-cafeteria": [
      "The book was a long cafeteria.",
    ],
    "sentence-list-the-book-was-a-long-novel": ["The book was a long novel."],
    "sentence-list-the-brave-soldier-was-fearless": [
      "The brave soldier was fearless.",
    ],
    "sentence-list-the-brave-soldier-was-somersault": [
      "The brave soldier was somersault.",
    ],
    "sentence-list-the-brook-swelled-with-crib": [
      "The brook swelled with crib.",
    ],
    "sentence-list-the-brook-swelled-with-rain": [
      "The brook swelled with rain.",
    ],
    "sentence-list-the-brownie-is-very-bird": ["The brownie is very bird."],
    "sentence-list-the-brownie-is-very-delicious": [
      "The brownie is very delicious.",
    ],
    "sentence-list-the-carpet-covers-the-floor": [
      "The carpet covers the floor.",
    ],
    "sentence-list-the-carpet-covers-the-orbit": [
      "The carpet covers the orbit.",
    ],
    "sentence-list-the-cat-scratched-my-advice": [
      "The cat scratched my advice.",
    ],
    "sentence-list-the-cat-scratched-my-hand": ["The cat scratched my hand."],
    "sentence-list-the-cheerleaders-yelled-the-cheers": [
      "The cheerleaders yelled the cheers.",
    ],
    "sentence-list-the-cheerleaders-yelled-the-sled": [
      "The cheerleaders yelled the sled.",
    ],
    "sentence-list-the-clam-held-a-climb": ["The clam held a climb."],
    "sentence-list-the-clam-held-a-pearl": ["The clam held a pearl."],
    "sentence-list-the-climbers-reached-the-astronomy": [
      "The climbers reached the astronomy.",
    ],
    "sentence-list-the-climbers-reached-the-peak": [
      "The climbers reached the peak.",
    ],
    "sentence-list-the-comedians-told-funny-icicle": [
      "The comedians told funny icicle.",
    ],
    "sentence-list-the-comedians-told-funny-jokes": [
      "The comedians told funny jokes.",
    ],
    "sentence-list-the-cool-breeze-felt-banana": [
      "The cool breeze felt banana.",
    ],
    "sentence-list-the-cool-breeze-felt-refreshing": [
      "The cool breeze felt refreshing.",
    ],
    "sentence-list-the-crew-sailed-the-acorn": ["The crew sailed the acorn."],
    "sentence-list-the-crew-sailed-the-ship": ["The crew sailed the ship."],
    "sentence-list-the-crutches-helped-him-volcano": [
      "The crutches helped him volcano.",
    ],
    "sentence-list-the-crutches-helped-him-walk": [
      "The crutches helped him walk.",
    ],
    "sentence-list-the-driver-witnessed-the-crash": [
      "The driver witnessed the crash.",
    ],
    "sentence-list-the-driver-witnessed-the-kitchen": [
      "The driver witnessed the kitchen.",
    ],
    "sentence-list-the-earthquake-shook-the-ground": [
      "The earthquake shook the ground.",
    ],
    "sentence-list-the-earthquake-shook-the-virtue": [
      "The earthquake shook the virtue.",
    ],
    "sentence-list-the-earthworm-loosens-the-dirt": [
      "The earthworm loosens the dirt.",
    ],
    "sentence-list-the-earthworm-loosens-the-sunset": [
      "The earthworm loosens the sunset.",
    ],
    "sentence-list-the-fire-alarm-rang-loudly": ["The fire alarm rang loudly."],
    "sentence-list-the-fire-alarm-rang-paint": ["The fire alarm rang paint."],
    "sentence-list-the-fireworks-exploded-in-screwdriver": [
      "The fireworks exploded in screwdriver.",
    ],
    "sentence-list-the-fireworks-exploded-in-unison": [
      "The fireworks exploded in unison.",
    ],
    "sentence-list-the-football-flew-fifty-broadcast": [
      "The football flew fifty broadcast.",
    ],
    "sentence-list-the-football-flew-fifty-yards": [
      "The football flew fifty yards.",
    ],
    "sentence-list-the-footprint-was-a-clue": ["The footprint was a clue."],
    "sentence-list-the-footprint-was-a-tomorrow": [
      "The footprint was a tomorrow.",
    ],
    "sentence-list-the-handwriting-was-in-cursive": [
      "The handwriting was in cursive.",
    ],
    "sentence-list-the-handwriting-was-in-daughter": [
      "The handwriting was in daughter.",
    ],
    "sentence-list-the-hen-laid-an-egg": ["The hen laid an egg."],
    "sentence-list-the-hen-laid-an-igloo": ["The hen laid an igloo."],
    "sentence-list-the-horse-pulled-a-bedtime": ["The horse pulled a bedtime."],
    "sentence-list-the-horse-pulled-a-cart": ["The horse pulled a cart."],
    "sentence-list-the-jar-is-closed-geese": ["The jar is closed geese."],
    "sentence-list-the-jar-is-closed-tightly": ["The jar is closed tightly."],
    "sentence-list-the-king-wore-a-crown": ["The king wore a crown."],
    "sentence-list-the-king-wore-a-hallway": ["The king wore a hallway."],
    "sentence-list-the-maid-cleaned-the-algebra": [
      "The maid cleaned the algebra.",
    ],
    "sentence-list-the-maid-cleaned-the-house": ["The maid cleaned the house."],
    "sentence-list-the-number-zero-equals-none": [
      "The number zero equals none.",
    ],
    "sentence-list-the-number-zero-equals-zebra": [
      "The number zero equals zebra.",
    ],
    "sentence-list-the-opposite-of-sad-is-happy": [
      "The opposite of sad is happy.",
    ],
    "sentence-list-the-opposite-of-sad-is-leather": [
      "The opposite of sad is leather.",
    ],
    "sentence-list-the-pelican-caught-a-city": ["The pelican caught a city."],
    "sentence-list-the-pelican-caught-a-fish": ["The pelican caught a fish."],
    "sentence-list-the-puppy-wagged-his-pigpen": [
      "The puppy wagged his pigpen.",
    ],
    "sentence-list-the-puppy-wagged-his-tail": ["The puppy wagged his tail."],
    "sentence-list-the-questions-were-answered-correctly": [
      "The questions were answered correctly.",
    ],
    "sentence-list-the-questions-were-answered-lamb": [
      "The questions were answered lamb.",
    ],
    "sentence-list-the-rain-soaked-the-crowd": ["The rain soaked the crowd."],
    "sentence-list-the-rain-soaked-the-exhale": ["The rain soaked the exhale."],
    "sentence-list-the-room-had-a-fireplace": ["The room had a fireplace."],
    "sentence-list-the-room-had-a-voyage": ["The room had a voyage."],
    "sentence-list-the-sleeping-child-was-chimney": [
      "The sleeping child was chimney.",
    ],
    "sentence-list-the-sleeping-child-was-quiet": [
      "The sleeping child was quiet.",
    ],
    "sentence-list-the-squirrels-stored-their-bedtime": [
      "The squirrels stored their bedtime.",
    ],
    "sentence-list-the-squirrels-stored-their-nuts": [
      "The squirrels stored their nuts.",
    ],
    "sentence-list-the-sun-always-shines-brightly": [
      "The sun always shines brightly.",
    ],
    "sentence-list-the-sun-always-shines-hamster": [
      "The sun always shines hamster.",
    ],
    "sentence-list-the-sunshine-made-me-hot": ["The sunshine made me hot."],
    "sentence-list-the-sunshine-made-me-sofa": ["The sunshine made me sofa."],
    "sentence-list-the-thunder-was-very-loud": ["The thunder was very loud."],
    "sentence-list-the-thunder-was-very-monkey": [
      "The thunder was very monkey.",
    ],
    "sentence-list-the-tornado-brought-strong-popcorn": [
      "The tornado brought strong popcorn.",
    ],
    "sentence-list-the-tornado-brought-strong-winds": [
      "The tornado brought strong winds.",
    ],
    "sentence-list-the-wind-blew-the-leaves": ["The wind blew the leaves."],
    "sentence-list-the-wind-blew-the-wonder": ["The wind blew the wonder."],
    "sentence-list-the-winning-racehorse-ran-fast": [
      "The winning racehorse ran fast.",
    ],
    "sentence-list-the-winning-racehorse-ran-raincoat": [
      "The winning racehorse ran raincoat.",
    ],
    "sentence-list-the-woman-wore-red-lipstick": [
      "The woman wore red lipstick.",
    ],
    "sentence-list-the-woman-wore-red-quicksand": [
      "The woman wore red quicksand.",
    ],
    "sentence-list-they-swam-across-the-bake": ["They swam across the bake."],
    "sentence-list-they-swam-across-the-pool": ["They swam across the pool."],
    "sentence-list-they-will-wake-at-dawn": ["They will wake at dawn."],
    "sentence-list-they-will-wake-at-neck": ["They will wake at neck."],
    "sentence-list-tires-are-made-of-planets": ["Tires are made of planets."],
    "sentence-list-tires-are-made-of-rubber": ["Tires are made of rubber."],
    "sentence-list-use-the-door-to-enter": ["Use the door to enter."],
    "sentence-list-use-the-door-to-thirsty": ["Use the door to thirsty."],
    "sentence-list-we-chew-with-our-hands": ["We chew with our hands."],
    "sentence-list-we-chew-with-our-teeth": ["We chew with our teeth."],
    "sentence-list-we-drove-on-the-cupcake": ["We drove on the cupcake."],
    "sentence-list-we-drove-on-the-highway": ["We drove on the highway."],
    "sentence-list-we-eat-lunch-at-noon": ["We eat lunch at noon."],
    "sentence-list-we-eat-lunch-at-ponytail": ["We eat lunch at ponytail."],
    "sentence-list-we-feel-with-our-eyes": ["We feel with our eyes."],
    "sentence-list-we-feel-with-our-hands": ["We feel with our hands."],
    "sentence-list-we-flew-a-kite-cry": ["We flew a kite cry."],
    "sentence-list-we-flew-a-kite-today": ["We flew a kite today."],
    "sentence-list-we-hear-with-our-ears": ["We hear with our ears."],
    "sentence-list-we-hear-with-our-feet": ["We hear with our feet."],
    "sentence-list-we-see-through-our-eyes": ["We see through our eyes."],
    "sentence-list-we-see-through-our-mouth": ["We see through our mouth."],
    "sentence-list-we-slept-in-our-dumbbell": ["We slept in our dumbbell."],
    "sentence-list-we-slept-in-our-tent": ["We slept in our tent."],
    "sentence-list-we-speak-with-our-ears": ["We speak with our ears."],
    "sentence-list-we-speak-with-our-mouth": ["We speak with our mouth."],
    "sentence-list-we-swam-in-the-afternoon": ["We swam in the afternoon."],
    "sentence-list-we-swam-in-the-telephone": ["We swam in the telephone."],
    "sentence-list-we-walk-on-our-feet": ["We walk on our feet."],
    "sentence-list-we-walk-on-our-teeth": ["We walk on our teeth."],
    "sentence-list-wear-tuxedos-to-formal-events": [
      "Wear tuxedos to formal events.",
    ],
    "sentence-list-wear-tuxedos-to-formal-oatmeal": [
      "Wear tuxedos to formal oatmeal.",
    ],
    "sentence-list-your-eyes-are-bloodshot-red": [
      "Your eyes are bloodshot red.",
    ],
    "sentence-list-your-eyes-are-bloodshot-seashore": [
      "Your eyes are bloodshot seashore.",
    ],
    "sentence-list-zebras-are-black-and-butter": [
      "Zebras are black and butter.",
    ],
    "sentence-list-zebras-are-black-and-white": ["Zebras are black and white."],
    "sentence-title": ["Sentence"],
    "sign-in-button-label": ["Sign In"],
    "start-test-button-label": ["Start Test"],
    "stop-or-go-go": ["Go"],
    "stop-or-go-instructions-by-color-except-message": ["Except Red"],
    "stop-or-go-instructions-by-color-message-1": [
      "Tap the spacebar <strong>ONCE</strong> if the word is presented in <strong>BLUE</strong> (regardless of which word it is).",
    ],
    "stop-or-go-instructions-by-color-message-2": [
      "<strong>*DO NOT*</strong> tap the spacebar if the word is presented in <strong>RED</strong> (regardless of which word it is).",
    ],
    "stop-or-go-instructions-by-word-except-message": ["Except Stop"],
    "stop-or-go-instructions-by-word-message-1": [
      'Tap the spacebar <strong>ONCE</strong> if the word <strong>"GO"</strong> appears.',
    ],
    "stop-or-go-instructions-by-word-message-2": [
      '<strong>*DO NOT*</strong> tap the spacebar if the word <strong>"STOP"</strong> appears.',
    ],
    "stop-or-go-instructions-message-1": [
      'In this test, the words <strong>"STOP"</strong> or <strong>"GO"</strong> will be presented one at a time.',
    ],
    "stop-or-go-instructions-message-2": [
      "Respond as quickly and accurately as possible.",
    ],
    "stop-or-go-instructions-message-3": [
      "Hit the spacebar to begin the task...",
    ],
    "stop-or-go-stop": ["Stop"],
    "stop-or-go-title": ["Stop or Go"],
    "tapping-instructions-message-1": [
      "Tap the spacebar as quickly as you can continuously for 30 seconds.",
    ],
    "tapping-instructions-message-2": [
      "Do not press and hold the spacebar.  Use <strong>ONLY</strong> your <strong>right</strong> hand if you are right-handed, or your <strong>left</strong> hand if you are left-handed.",
    ],
    "tapping-instructions-message-3": ["Tap the spacebar once to begin."],
    "tapping-instructions-message-4": ["You will have 3 seconds to prepare."],
    "tapping-instructions-start-task-message-1": [
      "Test starts in [[time]] seconds.",
    ],
    "tapping-title": ["Tapping"],
    "task-completed-message-1": [
      "Task completed. New instructions will be displayed shortly...",
    ],
    "task-completed-title": ["Task Completed"],
    "time-estimation-instructions-image-word": ["10 seconds"],
    "time-estimation-instructions-message-1": [
      "In this test, several periods of time (in seconds) will be presented as targets.",
    ],
    "time-estimation-instructions-message-2": [
      "For each target, tap the spacebar <strong>ONCE</strong> to begin and then <strong>ONCE</strong> after you feel the target time has been reached. Do not use a clock or watch.",
    ],
    "time-estimation-instructions-message-3": [
      "Respond as accurately as possible.",
    ],
    "time-estimation-instructions-message-4": [
      "Hit the spacebar to begin the task...",
    ],
    "time-estimation-instructions-start-task-message-1": [
      "Tap the spacebar once to begin.",
    ],
    "time-estimation-instructions-start-task-message-2": [
      "Tap the spacebar again after [[time]] seconds.",
    ],
    "time-estimation-title": ["Time Estimation"],
    title: ["English"],
    "updated-at": [{ _: "2016-11-15T17:42:49-06:00", $: { type: "dateTime" } }],
    "word-general-instructions-message-1": [
      "Respond as quickly and accurately as possible.",
    ],
    "word-general-instructions-message-2": [
      "Tap the <strong>right</strong> arrow key once to begin.",
    ],
    "word-instructions-image-word-left": ["battle"],
    "word-instructions-image-word-list": ["attend,water,battle"],
    "word-instructions-image-word-right": ["umpire"],
    "word-instructions-message-tap-left": [
      "Tap the <strong>left</strong> arrow key <strong>ONCE</strong> if the word on the <strong>left</strong> was on the list.",
    ],
    "word-instructions-message-tap-right": [
      "Tap the <strong>right</strong> arrow key <strong>ONCE</strong> if the word on the <strong>right</strong> was on the list.",
    ],
    "word-list-achieve": ["achieve"],
    "word-list-across": ["across"],
    "word-list-action": ["action"],
    "word-list-activity": ["activity"],
    "word-list-advantage": ["advantage"],
    "word-list-against": ["against"],
    "word-list-aggressive": ["aggressive"],
    "word-list-ahead": ["ahead"],
    "word-list-angry": ["angry"],
    "word-list-appreciate": ["appreciate"],
    "word-list-argue": ["argue"],
    "word-list-athlete": ["athlete"],
    "word-list-attack": ["attack"],
    "word-list-attend": ["attend"],
    "word-list-audible": ["audible"],
    "word-list-avoid": ["avoid"],
    "word-list-award": ["award"],
    "word-list-baseball": ["baseball"],
    "word-list-basic": ["basic"],
    "word-list-basketball": ["basketball"],
    "word-list-battle": ["battle"],
    "word-list-beginner": ["beginner"],
    "word-list-believe": ["believe"],
    "word-list-block": ["block"],
    "word-list-board": ["board"],
    "word-list-boundary": ["boundary"],
    "word-list-brave": ["brave"],
    "word-list-breathe": ["breathe"],
    "word-list-burst": ["burst"],
    "word-list-calm": ["calm"],
    "word-list-charge": ["charge"],
    "word-list-chase": ["chase"],
    "word-list-circle": ["circle"],
    "word-list-climb": ["climb"],
    "word-list-clinch": ["clinch"],
    "word-list-close": ["close"],
    "word-list-coach": ["coach"],
    "word-list-competition": ["competition"],
    "word-list-condition": ["condition"],
    "word-list-conscious": ["conscious"],
    "word-list-control": ["control"],
    "word-list-crash": ["crash"],
    "word-list-crowd": ["crowd"],
    "word-list-crush": ["crush"],
    "word-list-defeat": ["defeat"],
    "word-list-defense": ["defense"],
    "word-list-destroy": ["destroy"],
    "word-list-direction": ["direction"],
    "word-list-display": ["display"],
    "word-list-distance": ["distance"],
    "word-list-division": ["division"],
    "word-list-doctor": ["doctor"],
    "word-list-dominant": ["dominant"],
    "word-list-eager": ["eager"],
    "word-list-encourage": ["encourage"],
    "word-list-engage": ["engage"],
    "word-list-enormous": ["enormous"],
    "word-list-enough": ["enough"],
    "word-list-entertain": ["entertain"],
    "word-list-equal": ["equal"],
    "word-list-excel": ["excel"],
    "word-list-exercise": ["exercise"],
    "word-list-exert": ["exert"],
    "word-list-exhale": ["exhale"],
    "word-list-exhibit": ["exhibit"],
    "word-list-expert": ["expert"],
    "word-list-explode": ["explode"],
    "word-list-extra": ["extra"],
    "word-list-facility": ["facility"],
    "word-list-famous": ["famous"],
    "word-list-fearless": ["fearless"],
    "word-list-fell": ["fell"],
    "word-list-flag": ["flag"],
    "word-list-football": ["football"],
    "word-list-fundamental": ["fundamental"],
    "word-list-furious": ["furious"],
    "word-list-game": ["game"],
    "word-list-giant": ["giant"],
    "word-list-goal": ["goal"],
    "word-list-grind": ["grind"],
    "word-list-group": ["group"],
    "word-list-guard": ["guard"],
    "word-list-gym": ["gym"],
    "word-list-handle": ["handle"],
    "word-list-health": ["health"],
    "word-list-heart": ["heart"],
    "word-list-helmet": ["helmet"],
    "word-list-home": ["home"],
    "word-list-imbalance": ["imbalance"],
    "word-list-immovable": ["immovable"],
    "word-list-impress": ["impress"],
    "word-list-inches": ["inches"],
    "word-list-increase": ["increase"],
    "word-list-inside": ["inside"],
    "word-list-jaw": ["jaw"],
    "word-list-join": ["join"],
    "word-list-judge": ["judge"],
    "word-list-jump": ["jump"],
    "word-list-kick": ["kick"],
    "word-list-last": ["last"],
    "word-list-lead": ["lead"],
    "word-list-level": ["level"],
    "word-list-line": ["line"],
    "word-list-loud": ["loud"],
    "word-list-manage": ["manage"],
    "word-list-match": ["match"],
    "word-list-men": ["men"],
    "word-list-minor": ["minor"],
    "word-list-move": ["move"],
    "word-list-name": ["name"],
    "word-list-noise": ["noise"],
    "word-list-number": ["number"],
    "word-list-official": ["official"],
    "word-list-option": ["option"],
    "word-list-overrule": ["overrule"],
    "word-list-peak": ["peak"],
    "word-list-people": ["people"],
    "word-list-performance": ["performance"],
    "word-list-plan": ["plan"],
    "word-list-player": ["player"],
    "word-list-position": ["position"],
    "word-list-positive": ["positive"],
    "word-list-possible": ["possible"],
    "word-list-predict": ["predict"],
    "word-list-propel": ["propel"],
    "word-list-protect": ["protect"],
    "word-list-provide": ["provide"],
    "word-list-punish": ["punish"],
    "word-list-pursue": ["pursue"],
    "word-list-quick": ["quick"],
    "word-list-quit": ["quit"],
    "word-list-race": ["race"],
    "word-list-receive": ["receive"],
    "word-list-recover": ["recover"],
    "word-list-reduce": ["reduce"],
    "word-list-referee": ["referee"],
    "word-list-release": ["release"],
    "word-list-reply": ["reply"],
    "word-list-requirement": ["requirement"],
    "word-list-retrieve": ["retrieve"],
    "word-list-return": ["return"],
    "word-list-reward": ["reward"],
    "word-list-rough": ["rough"],
    "word-list-route": ["route"],
    "word-list-score": ["score"],
    "word-list-season": ["season"],
    "word-list-sideways": ["sideways"],
    "word-list-skate": ["skate"],
    "word-list-skill": ["skill"],
    "word-list-soccer": ["soccer"],
    "word-list-somersault": ["somersault"],
    "word-list-speedy": ["speedy"],
    "word-list-sport": ["sport"],
    "word-list-stadium": ["stadium"],
    "word-list-start": ["start"],
    "word-list-steal": ["steal"],
    "word-list-steam": ["steam"],
    "word-list-stretch": ["stretch"],
    "word-list-strong": ["strong"],
    "word-list-sweat": ["sweat"],
    "word-list-swift": ["swift"],
    "word-list-swim": ["swim"],
    "word-list-target": ["target"],
    "word-list-taunt": ["taunt"],
    "word-list-team": ["team"],
    "word-list-technical": ["technical"],
    "word-list-temper": ["temper"],
    "word-list-territory": ["territory"],
    "word-list-thirsty": ["thirsty"],
    "word-list-thrill": ["thrill"],
    "word-list-throw": ["throw"],
    "word-list-ticket": ["ticket"],
    "word-list-time": ["time"],
    "word-list-total": ["total"],
    "word-list-touch": ["touch"],
    "word-list-tough": ["tough"],
    "word-list-toward": ["toward"],
    "word-list-track": ["track"],
    "word-list-training": ["training"],
    "word-list-treatment": ["treatment"],
    "word-list-trouble": ["trouble"],
    "word-list-twist": ["twist"],
    "word-list-umpire": ["umpire"],
    "word-list-underdog": ["underdog"],
    "word-list-unfair": ["unfair"],
    "word-list-use": ["use"],
    "word-list-valiant": ["valiant"],
    "word-list-victory": ["victory"],
    "word-list-visitor": ["visitor"],
    "word-list-volleyball": ["volleyball"],
    "word-list-walk": ["walk"],
    "word-list-water": ["water"],
    "word-list-weak": ["weak"],
    "word-list-win": ["win"],
    "word-list-women": ["women"],
    "word-list-work": ["work"],
    "word-list-yard": ["yard"],
    "word-list-year": ["year"],
    "word-list-zero": ["zero"],
    "word-list-zone": ["zone"],
    "word-title": ["Word (Iteration [[index]])"],
    "word-with-list-message-1": [
      "In this test, a list of words will be presented one at a time...",
    ],
    "word-with-list-message-2": [
      "After the list is presented, pairs of words will be presented. One of the two words is from the list.",
    ],
    "word-with-pairs-message-1": [
      "In this test pairs of words will be presented. One of the two words in each pair is from the list presented earlier in the test battery.",
    ],
  };

  const es_strings = {
    _id: ["57ec19dba49ef6872b000001"],
    "created-at": [{ _: "2016-09-28T14:28:27-05:00", $: { type: "dateTime" } }],
    "creator-id": ["4f7a97fc72e2720007000002"],
    "email-label": ["E-mail"],
    "emotion-afraid": ["Susto"],
    "emotion-afraid-instruction-text": ["asustadas"],
    "emotion-angry": ["Enojo"],
    "emotion-angry-instruction-text": ["enojadas"],
    "emotion-happy": ["Felicidad"],
    "emotion-happy-instruction-text": ["felices"],
    "emotion-identification-instructions-message-1": [
      "En esta prueba, una serie de imágenes de caras que expresan emociones se presentará una a la vez.",
    ],
    "emotion-identification-instructions-message-2": [
      "Presione la tecla espacio <strong>UNA VEZ</strong> tan rápido como sea posible después de cada fotografía",
    ],
    "emotion-identification-instructions-message-3": [
      "<strong>*Excepto*</strong> para las caras [[emotion]].",
    ],
    "emotion-identification-instructions-message-4": [
      "Presione la barra espaciadora para comenzar la tarea...",
    ],
    "emotion-identification-title": [
      "Identificador de Emociones (Excepto [[emotion]])",
    ],
    "emotion-neutral": ["Neutralidad"],
    "emotion-neutral-instruction-text": ["neutrales"],
    "emotion-sad": ["Tristeza"],
    "emotion-sad-instruction-text": ["tristes"],
    "facial-recognition-image-text": [
      "Esta es la persona que he visto anteriormente",
    ],
    "facial-recognition-instructions-message-1": [
      "En esta prueba , se presentarán pares de fotografías de rostros.",
    ],
    "facial-recognition-instructions-message-2": [
      "Presione la tecla de flecha  <strong>derecha</strong> <strong>UNA VEZ</strong> si usted reconoce que el rostro en la <strong>derecha</strong> fue presentado anteriormente en la prueba actual.",
    ],
    "facial-recognition-instructions-message-3": [
      "Presione la tecla de flecha  <strong>izquierda</strong> <strong>UNA VEZ</strong> si usted reconoce que el rostro en la <strong>izquierda</strong> fue presentado anteriormente en la prueba actual.",
    ],
    "facial-recognition-instructions-message-4": [
      "Presione la tecla de flecha <strong>derecha</strong> una vez para empezar.",
    ],
    "facial-recognition-title": ["Reconocimiento Facial"],
    "finished-test-message": ["Usted ha terminado el XLNTbrain-Cog Test."],
    "go-to-sign-in-button-label": ["Vaya a la página de inicio de sesión"],
    "landing-page-message-1": [
      "Usted está a punto de comenzar la prueba de XLNTbrain.",
    ],
    "landing-page-message-2": [
      "Esta prueba se utiliza para comprobar su rendimiento. No obtenga ayuda de otra persona , excepto para entender las instrucciones.",
    ],
    "landing-page-message-3": [
      "Lea las instrucciones en cada sección del examen cuidadosamente antes de empezar.",
    ],
    "landing-page-message-4": [
      "Esta prueba tendrá una duración aproximada de 35 minutos. Por favor, asegúrese de que tenga tiempo suficiente para completar la prueba.",
    ],
    "landing-page-message-5": [
      "Antes de comenzar la prueba, asegúrese de que su entorno es tranquilo y libre de distracciones tales como teléfonos, dispositivos electrónicos y otras personas.",
    ],
    "language-key": ["es"],
    "log-report-button-label": ["Enviar Reporte"],
    "login-in-progress": ["Inicio de sesión en progreso..."],
    "login-message-1": [
      "Usar el mismo e-mail y contraseña que creó para xlntbrain.com.",
    ],
    "login-message-2": [
      "Cierre todos los programas que se ejecuten en el equipo antes de iniciar su prueba.",
    ],
    "next-step-button-label": ["Siguiente Paso"],
    "next-task-button-label": ["Siguiente Tarea"],
    "one-back-instructions-message-1": [
      "En esta prueba, una serie de fotografías se presentarán de una a la vez.",
    ],
    "one-back-instructions-message-2": [
      "Presione la barra espaciadora <strong>UNA VEZ</strong> después de cada nueva fotografía.",
    ],
    "one-back-instructions-message-3": [
      "No presione la barra espaciadora si la fotografía es la misma que la presentada antes de la misma.",
    ],
    "one-back-instructions-message-4": [
      "Presione la barra espaciadora una vez para comenzar.",
    ],
    "one-back-title": ["Uno hacia atrás"],
    "password-label": ["Contraseña"],
    "questionnaire-button-next": ["Siguiente"],
    "questionnaire-button-no": ["No"],
    "questionnaire-button-previous": ["Anterior"],
    "questionnaire-button-yes": ["Sí"],
    "questionnaire-instructions-message-1": [
      "Por favor díganos la frecuencia con que han experimentado los siguientes síntomas en los últimos 30 días . Si usted está tomando esta prueba después de sufrir una potencial conmoción cerebral, entonces reporte los síntomas que usted ha estado experimentando en las últimas 24 horas . Trate de ser lo más preciso posible.",
    ],
    "questionnaire-instructions-message-2": [
      "Presione la barra espaciadora para comenzar la tarea...",
    ],
    "questionnaire-option-never": ["Nunca"],
    "questionnaire-option-often": ["Frecuentemente"],
    "questionnaire-option-sometimes": ["A veces"],
    "questionnaire-option-very-often": ["Muy frecuentemente"],
    "questionnaire-question-1": ["He estado teniendo dolores de cabeza."],
    "questionnaire-question-10": ["He estado durmiendo demasiado"],
    "questionnaire-question-11": ["He estado preocupado."],
    "questionnaire-question-12": [
      "He estado teniendo problemas para encontrar las palabras correctas.",
    ],
    "questionnaire-question-13": ["He sentido náuseas."],
    "questionnaire-question-14": ["Me he sentido enojado."],
    "questionnaire-question-15": ["Me he sentido triste."],
    "questionnaire-question-16": ["No he estado durmiendo lo suficiente."],
    "questionnaire-question-17": ["Me he sentido irritable."],
    "questionnaire-question-18": ["He estado sensible a la luz o sonido ."],
    "questionnaire-question-19": [
      "Le he estado gritando a las personas cuando estoy enojado.",
    ],
    "questionnaire-question-2": ["Me he sentido mentalmente confundido"],
    "questionnaire-question-20": [
      "He estado teniendo problemas para quedarme dormido.",
    ],
    "questionnaire-question-21": ["Me he estado cayendo ."],
    "questionnaire-question-22": [
      "Mis pensamientos no me dejan conciliar el sueño.",
    ],
    "questionnaire-question-23": [
      "He estado teniendo problemas para recordar cosas.",
    ],
    "questionnaire-question-24": ["Se me ha hecho difícil resolver problemas."],
    "questionnaire-question-25": ["Me he sentido fuera de equilibrio"],
    "questionnaire-question-26": ["Me he sentido cansado."],
    "questionnaire-question-27": ["Me he sentido impotente y desesperado ."],
    "questionnaire-question-28": ["Me he sentido mareado."],
    "questionnaire-question-29": ["He estado sin coordinación."],
    "questionnaire-question-3": ["Me he sentido nervioso."],
    "questionnaire-question-30": ["He estado llorando mucho."],
    "questionnaire-question-31": ["He sentido que mi corazón late rápido."],
    "questionnaire-question-32": [
      "He estado sintiéndome preocupado o como si algo malo estuviera a punto de ocurrir.",
    ],
    "questionnaire-question-33": [
      "He estado perdiendo el control cuando estoy enojado.",
    ],
    "questionnaire-question-34": ["He estado peleando mucho."],
    "questionnaire-question-35": ["He estado sintiéndome torpe."],
    "questionnaire-question-36": ["¿Estás tomando algún medicamento?"],
    "questionnaire-question-4": ["He estado teniendo visión borrosa."],
    "questionnaire-question-5": [
      "He estado tirando o rompiendo cosas cuando estoy enojado.",
    ],
    "questionnaire-question-6": [
      "He estado teniendo problemas para mantenerme enfocado.",
    ],
    "questionnaire-question-7": ["He estado vomitando."],
    "questionnaire-question-8": [
      "He estado demasiado somnoliento durante el día .",
    ],
    "questionnaire-question-9": ["No he sentido ganas de hacer nada ."],
    "questionnaire-title": ["Verificar Lista de Síntomas"],
    "report-has-been-sent-message": ["¡El reporte ha sido enviado!"],
    "sending-report-message": ["Enviando el reporte. Por favor espere..."],
    "sentence-instructions-animation-sentence": ["Este es el gran total."],
    "sentence-instructions-make-sense": ["Tiene sentido..."],
    "sentence-instructions-message-1": [
      "En esta prueba , una serie de 5 palabras de una frase presentarán una a la vez.",
    ],
    "sentence-instructions-message-2": [
      "Presione la tecla de flecha <strong>derecha</strong> <strong>UNA VEZ</strong> si la última palabra de cada frase tiene sentido.",
    ],
    "sentence-instructions-message-3": [
      "Presione la tecla de flecha <strong>izquierda</strong> <strong>UNA VEZ</strong> si la última palabra de cada frase no tiene sentido.",
    ],
    "sentence-instructions-message-4": [
      "Responda lo mas rápido y preciso posible.",
    ],
    "sentence-instructions-message-5": [
      "Presione la tecla de flecha <strong>derecha</strong> una vez para empezar.",
    ],
    "sentence-instructions-not-make-sense": ["No tiene sentido..."],
    "sentence-list-a-bird-can-fly-firemen": ["Un pájaro puede volar bombero."],
    "sentence-list-a-bird-can-fly-high": ["Un pájaro puede volar alto."],
    "sentence-list-a-bug-crawled-on-me": ["Un insecto caminó sobre mi."],
    "sentence-list-a-bug-crawled-on-midnight": [
      "Un insecto caminó sobre medianoche.",
    ],
    "sentence-list-a-cowboy-can-rope-cattle": [
      "Un vaquero puede enlazar ganado.",
    ],
    "sentence-list-a-cowboy-can-rope-equator": [
      "Un vaquero puede enlazar ecuador.",
    ],
    "sentence-list-a-cube-has-six-playpens": ["Un cubo tiene seis corrales."],
    "sentence-list-a-cube-has-six-sides": ["Un cubo tiene seis caras."],
    "sentence-list-a-day-is-twentyfour-hours": [
      "Un día tiene veinticuatro horas.",
    ],
    "sentence-list-a-day-is-twentyfour-oranges": [
      "Un día tiene veinticuatro naranjas.",
    ],
    "sentence-list-a-dragonfly-is-a-bug": ["Una libélula es un insecto."],
    "sentence-list-a-dragonfly-is-a-scaffold": ["Una libélula es un andamio."],
    "sentence-list-a-green-light-means-crown": [
      "Una luz verde significa corona.",
    ],
    "sentence-list-a-green-light-means-go": ["Una luz verde significa pasar."],
    "sentence-list-a-jellyfish-has-long-burglar": [
      "Una medusa tiene largos ladrón.",
    ],
    "sentence-list-a-jellyfish-has-long-tentacles": [
      "Una medusa tiene largos tentáculos.",
    ],
    "sentence-list-a-lifeguard-rescued-a-swimmer": [
      "Un salvavidas rescató un nadador.",
    ],
    "sentence-list-a-lifeguard-rescued-a-yesterday": [
      "Un salvavidas rescató un ayer.",
    ],
    "sentence-list-a-minute-is-sixty-ants": [
      "Un minuto tiene sesenta hormigas.",
    ],
    "sentence-list-a-minute-is-sixty-seconds": [
      "Un minuto tiene sesenta segundos.",
    ],
    "sentence-list-a-mountain-is-very-cough": ["Una montaña es muy tos."],
    "sentence-list-a-mountain-is-very-large": ["Una montaña es muy grande."],
    "sentence-list-a-rattlesnake-shakes-its-holiday": [
      "Una cascabel sacude su fiesta.",
    ],
    "sentence-list-a-rattlesnake-shakes-its-tail": [
      "Una cascabel sacude su cola.",
    ],
    "sentence-list-a-red-light-means-donkey": ["Una luz roja significa burro."],
    "sentence-list-a-red-light-means-stop": [
      "Una luz roja significa detenerse.",
    ],
    "sentence-list-a-shamrock-is-a-clover": ["Un shamrock es un trébol"],
    "sentence-list-a-shamrock-is-a-sunk": ["Un shamrock es un hundido."],
    "sentence-list-a-tombstone-marked-the-grave": [
      "Una lápida marca la tumba.",
    ],
    "sentence-list-a-tombstone-marked-the-growl": [
      "Una lápida marca la gruñido.",
    ],
    "sentence-list-a-tree-blocked-the-calendar": [
      "Un árbol bloqueó el calendario.",
    ],
    "sentence-list-a-tree-blocked-the-path": ["Un árbol bloqueó el camino."],
    "sentence-list-a-week-is-seven-days": ["Una semana tiene siete días."],
    "sentence-list-a-week-is-seven-hares": ["Una semana tiene siete liebres."],
    "sentence-list-a-year-is-twelve-dimes": ["Un año tiene doce centavos."],
    "sentence-list-a-year-is-twelve-months": ["Un año tiene doce meses."],
    "sentence-list-actions-speak-louder-than-plantation": [
      "Acciones hablan más que plantación.",
    ],
    "sentence-list-actions-speak-louder-than-words": [
      "Acciones hablan más que palabras.",
    ],
    "sentence-list-adding-numbers-gives-a-ladybug": [
      "Sumar números da un mariquita.",
    ],
    "sentence-list-adding-numbers-gives-a-total": [
      "Sumar números da un total.",
    ],
    "sentence-list-advance-warnings-are-safety-alerts": [
      "Advertencias son alertas de seguridad.",
    ],
    "sentence-list-advance-warnings-are-safety-lettuce": [
      "Advertencias son alertas de lechuga.",
    ],
    "sentence-list-always-breathe-when-you-cucumber": [
      "Siempre respira cuando hagas pepino.",
    ],
    "sentence-list-always-breathe-when-you-exercise": [
      "Siempre respira cuando hagas ejercicio.",
    ],
    "sentence-list-an-elephants-trunk-is-cobweb": [
      "Trompa de elefante es teleraña.",
    ],
    "sentence-list-an-elephants-trunk-is-long": [
      "Trompa de elefante es larga.",
    ],
    "sentence-list-an-hour-is-sixty-minutes": [
      "Una hora tiene sesenta minutos.",
    ],
    "sentence-list-an-hour-is-sixty-umbrellas": [
      "Una hora tiene sesenta paraguas.",
    ],
    "sentence-list-any-child-would-love-candy": [
      "Cualquier niño ama el caramelo.",
    ],
    "sentence-list-any-child-would-love-scarce": [
      "Cualquier niño ama el escaso.",
    ],
    "sentence-list-babies-are-born-in-hospitals": [
      "Los bebés nacen en hospitales.",
    ],
    "sentence-list-babies-are-born-in-shoulder": [
      "Los bebés nacen en hombros.",
    ],
    "sentence-list-blue-is-my-favorite-color": ["Azul es mi color favorito."],
    "sentence-list-blue-is-my-favorite-quest": ["Azul es mi color búsqueda."],
    "sentence-list-breakfast-is-my-favorite-lamp": [
      "Desayuno es mi comida lámpara.",
    ],
    "sentence-list-breakfast-is-my-favorite-meal": [
      "Desayuno es mi comida favorita.",
    ],
    "sentence-list-bring-your-lunch-to-ivory": ["Traiga almuerzo a la marfil."],
    "sentence-list-bring-your-lunch-to-school": [
      "Traiga almuerzo a la escuela",
    ],
    "sentence-list-call-me-on-the-phone": ["Me llamas por el teléfono."],
    "sentence-list-call-me-on-the-squirrel": ["Me llamas por el ardilla."],
    "sentence-list-cars-travel-on-the-laugh": ["Coches circulan por la risa."],
    "sentence-list-cars-travel-on-the-road": [
      "Coches circulan por la carretera.",
    ],
    "sentence-list-clowns-crammed-into-a-car": [
      "Payasos amontonados en un coche.",
    ],
    "sentence-list-clowns-crammed-into-a-knee": [
      "Payasos amontonados en un rodilla.",
    ],
    "sentence-list-cookies-baked-in-the-chalkboard": [
      "Galletas cocidas en el pizarra.",
    ],
    "sentence-list-cookies-baked-in-the-oven": [
      "Galletas cocidas en el horno.",
    ],
    "sentence-list-cut-cardboard-with-sharp-scissors": [
      "Cartulina cortada con afiladas tijeras.",
    ],
    "sentence-list-cut-cardboard-with-sharp-trouble": [
      "Cartulina cortada con afiladas tijeras.",
    ],
    "sentence-list-cut-meat-with-sharp-knives": [
      "Cortar carne con cuchillos afilados.",
    ],
    "sentence-list-cut-meat-with-sharp-skyline": [
      "Cortar carne con cuchillos rascacielos.",
    ],
    "sentence-list-do-not-block-my-careless": ["No me bloquee el descuidado."],
    "sentence-list-do-not-block-my-path": ["No me bloquee el camino."],
    "sentence-list-doctors-help-cure-sick-muffins": [
      "Doctores ayudan a curar magdalenas.",
    ],
    "sentence-list-doctors-help-cure-sick-patients": [
      "Doctores ayudan a curar enfermos.",
    ],
    "sentence-list-dogs-bark-at-other-animals": [
      "Perros ladran a otros animales.",
    ],
    "sentence-list-dogs-bark-at-other-eggnog": [
      "Perros ladran a otros ponche.",
    ],
    "sentence-list-ducks-floated-on-the-pond": [
      "Patos flotando en el estanque.",
    ],
    "sentence-list-ducks-floated-on-the-suntan": [
      "Patos flotando en el bronceado.",
    ],
    "sentence-list-eyeglasses-help-me-see-clearly": [
      "Anteojos ayudan a ver claramente.",
    ],
    "sentence-list-eyeglasses-help-me-see-wrench": [
      "Anteojos ayudan a ver llave.",
    ],
    "sentence-list-five-nickels-equal-one-hour": [
      "Cinco centavos equivalen a hora.",
    ],
    "sentence-list-five-nickels-equal-one-quarter": [
      "Cinco centavos equivalen a cuarto.",
    ],
    "sentence-list-five-pennies-equal-one-nickel": [
      "Cinco centavos equivalen a níquel.",
    ],
    "sentence-list-five-pennies-equal-one-second": [
      "Cinco centavos equivalen a segundo.",
    ],
    "sentence-list-flamingos-stand-on-one-foot": [
      "Flamencos parados en una pata.",
    ],
    "sentence-list-flamingos-stand-on-one-stew": [
      "Flamencos parados en una estofado.",
    ],
    "sentence-list-football-players-are-good-athletes": [
      "Los futbolistas son buenos atletas.",
    ],
    "sentence-list-football-players-are-good-rust": [
      "Los futbolistas son buenos moho.",
    ],
    "sentence-list-four-quarters-equal-one-dollar": [
      "Cuatro cuartos equivalen a dólar.",
    ],
    "sentence-list-four-quarters-equal-one-month": [
      "Cuatro cuartos equivalen a mes.",
    ],
    "sentence-list-fruit-is-a-healthy-pencil": ["Fruta es un saludable lápiz."],
    "sentence-list-fruit-is-a-healthy-snack": [
      "Fruta es un saludable bocadillo.",
    ],
    "sentence-list-funny-jokes-make-me-airport": [
      "Los chistes me hacen aeropuerto.",
    ],
    "sentence-list-funny-jokes-make-me-laugh": ["Los chistes me hacen reír."],
    "sentence-list-giraffes-have-very-long-footballs": [
      "Las jirafas tienen cuello fútbol.",
    ],
    "sentence-list-giraffes-have-very-long-necks": [
      "Las jirafas tienen cuello largo.",
    ],
    "sentence-list-gray-is-darker-than-construction": [
      "Gris más oscuro que construcción.",
    ],
    "sentence-list-gray-is-darker-than-white": ["Gris más oscuro que blanco."],
    "sentence-list-gray-is-lighter-than-black": ["Gris más claro que negro."],
    "sentence-list-gray-is-lighter-than-island": ["Gris más claro que isla."],
    "sentence-list-he-climbed-over-the-echo": ["Se subió sobre la eco."],
    "sentence-list-he-climbed-over-the-hill": ["Se subió sobre la colina."],
    "sentence-list-he-drew-a-straight-line": ["Él trazó una línea recta."],
    "sentence-list-he-drew-a-straight-unhappy": ["Él trazó una línea infeliz."],
    "sentence-list-he-ran-like-the-book": ["Él corrió como el libro."],
    "sentence-list-he-ran-like-the-wind": ["Él corrió como el viento."],
    "sentence-list-he-ran-toward-me-foundation": [
      "Él corrió hacia mí fundamento.",
    ],
    "sentence-list-he-ran-toward-me-quickly": [
      "Él corrió hacia mí rápidamente.",
    ],
    "sentence-list-her-right-arm-was-broken": ["Su brazo derecho estaba roto."],
    "sentence-list-her-right-arm-was-cemetery": [
      "Su brazo derecho estaba cementerio.",
    ],
    "sentence-list-his-belt-was-too-tight": [
      "Su cinturón estaba demasiado apretado.",
    ],
    "sentence-list-his-belt-was-too-waterfall": [
      "Su cinturón estaba demasiado cascada.",
    ],
    "sentence-list-his-loud-voice-was-audible": ["Su gran voz era audible."],
    "sentence-list-his-loud-voice-was-climb": ["Su gran voz era ascenso."],
    "sentence-list-i-appreciate-eating-good-food": [
      "Yo agradezco la buena comida.",
    ],
    "sentence-list-i-appreciate-eating-good-window": [
      "Yo agradezco la buena ventana.",
    ],
    "sentence-list-i-can-ride-my-fireplace": ["Yo puedo conducir mi chimenea."],
    "sentence-list-i-can-ride-my-scooter": ["Yo puedo conducir mi scooter."],
    "sentence-list-i-chat-on-the-cartwheel": ["Yo converso por el voltereta."],
    "sentence-list-i-chat-on-the-telephone": ["Yo converso por el teléfono."],
    "sentence-list-i-cry-when-i-cupboard": ["Yo lloro cuando me alacena."],
    "sentence-list-i-cry-when-i-fall": ["Yo lloro cuando me caigo."],
    "sentence-list-i-did-my-math-apartment": ["Hice mi tarea de apartmento."],
    "sentence-list-i-did-my-math-homework": ["Hice mi tarea de matemáticas."],
    "sentence-list-i-drink-milk-with-billboard": [
      "Tomo leche en el cartelera.",
    ],
    "sentence-list-i-drink-milk-with-breakfast": ["Tomo leche en el desayuno."],
    "sentence-list-i-drink-through-a-straw": ["Puedo beber con una pajita."],
    "sentence-list-i-drink-through-a-toe": ["Puedo beber con una dedo."],
    "sentence-list-i-fell-down-the-hill": ["Caí debajo de la colina."],
    "sentence-list-i-fell-down-the-notebook": ["Caí debajo de la computadora."],
    "sentence-list-i-got-stuck-in-leaf": ["Quedé atrapado en el hoja."],
    "sentence-list-i-got-stuck-in-traffic": ["Quedé atrapado en el tráfico."],
    "sentence-list-i-grew-two-inches-taller": ["Crecí dos pulgadas más alto."],
    "sentence-list-i-grew-two-inches-wilderness": [
      "Crecí dos pulgadas más desierto.",
    ],
    "sentence-list-i-have-a-pet-dog": ["tengo de mascota un perro."],
    "sentence-list-i-have-a-pet-pavilion": ["tengo de mascota un pabellón."],
    "sentence-list-i-like-hot-beef-shoe": [
      "Prefiero estofado caliente de zapato.",
    ],
    "sentence-list-i-like-hot-beef-stew": [
      "Prefiero estofado caliente de carne.",
    ],
    "sentence-list-i-live-with-my-family": ["Yo vivo con mi familia."],
    "sentence-list-i-live-with-my-hoist": ["Yo vivo con mi polea."],
    "sentence-list-i-moved-ahead-in-lamp": ["Me moví adelante en lámpara."],
    "sentence-list-i-moved-ahead-in-line": ["Me moví adelante en línea."],
    "sentence-list-i-raced-in-my-pebble": ["Corrí en zapatillas de gravilla."],
    "sentence-list-i-raced-in-my-sneakers": ["Corrí en zapatillas de deporte."],
    "sentence-list-i-rode-my-bike-bacon": ["Rodé en mi bicicleta tocino."],
    "sentence-list-i-rode-my-bike-yesterday": ["Rodé en mi bicicleta ayer."],
    "sentence-list-i-shiver-from-the-bucket": ["Yo tiemblo por el balde."],
    "sentence-list-i-shiver-from-the-cold": ["Yo tiemblo por el frío."],
    "sentence-list-i-tore-the-paper-apart": ["Yo rompí el documento añicos."],
    "sentence-list-i-tore-the-paper-squirt": ["Yo rompí el documento chorro."],
    "sentence-list-i-understand-the-words-meaning": [
      "Entiendo el significado de palabras.",
    ],
    "sentence-list-i-understand-the-words-scarecrow": [
      "Entiendo el significado de espantapájaros.",
    ],
    "sentence-list-i-walk-on-the-roar": ["Yo camino por la rugido."],
    "sentence-list-i-walk-on-the-sidewalk": ["Yo camino por la acera."],
    "sentence-list-i-want-to-live-explode": ["Yo quiero vivir para explotar."],
    "sentence-list-i-want-to-live-forever": ["Yo quiero vivir para siempre."],
    "sentence-list-i-went-to-the-gone": ["Yo me fui al ir."],
    "sentence-list-i-went-to-the-park": ["Yo me fui al parque."],
    "sentence-list-i-will-cook-scrambled-eggs": [
      "Voy a cocinar huevos revueltos.",
    ],
    "sentence-list-i-will-cook-scrambled-railway": [
      "Voy a cocinar huevos ferrocarril.",
    ],
    "sentence-list-i-will-read-the-book": ["Voy a leer el libro."],
    "sentence-list-i-will-read-the-brush": ["Voy a leer el cepillo."],
    "sentence-list-i-wore-a-gold-necklace": ["Usé un collar de oro."],
    "sentence-list-i-wore-a-gold-ocean": ["Usé un collar de oceano."],
    "sentence-list-it-gets-dark-after-sister": [
      "Se oscurese después del hermana.",
    ],
    "sentence-list-it-gets-dark-after-sunset": [
      "Se oscurece después del atardecer.",
    ],
    "sentence-list-it-is-cold-in-directory": ["Hace más frio en directorio."],
    "sentence-list-it-is-cold-in-winter": ["Hace más frio en invierno."],
    "sentence-list-it-is-dark-at-night": ["Es oscuro por la noche."],
    "sentence-list-it-is-dark-at-seesaw": ["Es oscuro por la balancín."],
    "sentence-list-it-is-hot-in-homework": ["Hace mas calor en tarea."],
    "sentence-list-it-is-hot-in-summer": ["Hace mas calor en verano."],
    "sentence-list-jack-ran-through-the-snug": ["Jack corrió por el ajustado."],
    "sentence-list-jack-ran-through-the-yard": ["Jack corrió por el patio."],
    "sentence-list-jane-is-a-fast-swimmer": ["Jane es una rápida nadadora."],
    "sentence-list-jane-is-a-fast-tent": ["Jane es una rápida carpa."],
    "sentence-list-jump-to-get-the-hour": ["Saltar para conseguir el hora."],
    "sentence-list-jump-to-get-the-rebound": [
      "Saltar para conseguir el rebote.",
    ],
    "sentence-list-kickball-is-my-favorite-carburetor": [
      "Kickball es mi juego carburador.",
    ],
    "sentence-list-kickball-is-my-favorite-game": [
      "Kickball es mi juego favorito.",
    ],
    "sentence-list-mountains-are-taller-than-hills": [
      "Montañas más altas que colinas.",
    ],
    "sentence-list-mountains-are-taller-than-sleet": [
      "Montañas más altas que aguanieve.",
    ],
    "sentence-list-my-attack-dog-is-aggressive": ["Mi perro es muy agresivo."],
    "sentence-list-my-attack-dog-is-mailbox": ["Mi perro es muy buzón."],
    "sentence-list-my-heart-is-beating-fast": [
      "Mi corazón está latiendo rápidamente.",
    ],
    "sentence-list-my-heart-is-beating-sidewalk": [
      "Mi corazón está latiendo acera.",
    ],
    "sentence-list-my-new-bike-arrived-necktie": [
      "Mi bicicleta nueva llegó corbata.",
    ],
    "sentence-list-my-new-bike-arrived-today": [
      "Mi bicicleta nueva llegó hoy.",
    ],
    "sentence-list-my-toothpaste-is-mint-flavored": [
      "Mi pasta tiene sabor menta.",
    ],
    "sentence-list-my-toothpaste-is-mint-woodchuck": [
      "Mi pasta tiene sabor marmota.",
    ],
    "sentence-list-never-walk-alone-at-night": ["Nunca camine solo de noche."],
    "sentence-list-never-walk-alone-at-peanut": ["Nunca camine solo de maní."],
    "sentence-list-paintings-hung-on-the-noise": [
      "Pinturas colgadas en la ruido.",
    ],
    "sentence-list-paintings-hung-on-the-wall": [
      "Pinturas colgadas en la pared.",
    ],
    "sentence-list-plan-ahead-to-prevent-delays": [
      "Planear con anticipación evita retrasos.",
    ],
    "sentence-list-plan-ahead-to-prevent-helmet": [
      "Planear con anticipación evita casco.",
    ],
    "sentence-list-planes-land-at-the-airport": [
      "Aviones aterrizan en el aeropuerto.",
    ],
    "sentence-list-planes-land-at-the-frugal": [
      "Aviones aterrizan en el frugal.",
    ],
    "sentence-list-planets-move-around-the-stocking": [
      "Planetas giran alrededor del almacenamiento.",
    ],
    "sentence-list-planets-move-around-the-sun": [
      "Planetas giran alrededor del sol.",
    ],
    "sentence-list-players-sat-on-the-sideline": [
      "Jugadores sentados en la banca.",
    ],
    "sentence-list-players-sat-on-the-subtraction": [
      "Jugadores sentados en la resta.",
    ],
    "sentence-list-rhinos-have-a-bony-doghouse": [
      "Rinocerontes tienen un cuerno casa.",
    ],
    "sentence-list-rhinos-have-a-bony-horn": [
      "Rinocerontes tienen un cuerno óseo.",
    ],
    "sentence-list-riding-a-rollercoaster-is-jellyfish": [
      "Una montaña rusa es medusa.",
    ],
    "sentence-list-riding-a-rollercoaster-is-thrilling": [
      "Una montaña rusa es emocionante.",
    ],
    "sentence-list-rockets-launched-up-into-pancake": [
      "Cohetes lanzados hacia el crepe.",
    ],
    "sentence-list-rockets-launched-up-into-space": [
      "Cohetes lanzados hacia el espacio.",
    ],
    "sentence-list-rocks-are-heavier-than-circus": [
      "Rocas pesan mas que circo.",
    ],
    "sentence-list-rocks-are-heavier-than-feathers": [
      "Rocas pesan mas que plumas.",
    ],
    "sentence-list-runners-jumped-over-the-hurdle": [
      "Corredores saltaron sobre el obstáculo.",
    ],
    "sentence-list-runners-jumped-over-the-outside": [
      "Corredores saltaron sobre el afuera.",
    ],
    "sentence-list-seagulls-live-by-the-crouton": [
      "Gaviotas viven en el crutón.",
    ],
    "sentence-list-seagulls-live-by-the-sea": ["Gaviotas viven en el mar."],
    "sentence-list-she-bent-over-to-birthday": [
      "Ella se inclinó para cumpleaños.",
    ],
    "sentence-list-she-bent-over-to-stretch": ["Ella se inclinó para estirar."],
    "sentence-list-she-slipped-and-fell-boxcar": ["Se resbaló y se vagón."],
    "sentence-list-she-slipped-and-fell-down": ["Se resbaló y se cayó"],
    "sentence-list-ships-sail-across-the-juggle": [
      "Barcos navegan por el malabares.",
    ],
    "sentence-list-ships-sail-across-the-sea": ["Barcos navegan por el mar."],
    "sentence-list-shrimp-is-my-favorite-impatient": [
      "Camarón es mi comida impaciente.",
    ],
    "sentence-list-shrimp-is-my-favorite-seafood": [
      "Camarón es mi comida favorita.",
    ],
    "sentence-list-tables-are-flat-on-bluebird": [
      "Las mesas son planas azulejos.",
    ],
    "sentence-list-tables-are-flat-on-top": ["Las mesas son planas arriba."],
    "sentence-list-ten-dimes-equal-one-day": ["Diez centavos son un día."],
    "sentence-list-ten-dimes-equal-one-dollar": ["Diez centavos son un dólar."],
    "sentence-list-the-baby-slept-in-peace": ["El bebé durmió en paz."],
    "sentence-list-the-baby-slept-in-toothbrush": [
      "El bebé durmió en cepillo.",
    ],
    "sentence-list-the-baby-weighed-seven-overrule": [
      "El bebé pesó siete anular.",
    ],
    "sentence-list-the-baby-weighed-seven-pounds": [
      "El bebé pesó siete libras.",
    ],
    "sentence-list-the-bandaid-healed-my-carrot": [
      "La tirita sanó mi zanahoria.",
    ],
    "sentence-list-the-bandaid-healed-my-cut": ["La tirita sanó mi corte."],
    "sentence-list-the-battle-lasted-many-skates": [
      "La batalla duró muchos patines.",
    ],
    "sentence-list-the-battle-lasted-many-years": [
      "La batalla duró muchos años.",
    ],
    "sentence-list-the-big-bee-stung-me": ["La abeja grande me picó."],
    "sentence-list-the-big-bee-stung-rainbow": ["La abeja grande me arcoiris."],
    "sentence-list-the-blue-bird-flew-away": ["El pájaro azul huyó volando."],
    "sentence-list-the-blue-bird-flew-toaster": [
      "El pájaro azul huyó tostadora.",
    ],
    "sentence-list-the-book-was-a-long-cafeteria": [
      "Libro era una novela cafetería.",
    ],
    "sentence-list-the-book-was-a-long-novel": ["Libro era una novela larga."],
    "sentence-list-the-brave-soldier-was-fearless": [
      "El soldado no tenía miedo.",
    ],
    "sentence-list-the-brave-soldier-was-somersault": [
      "El soldado no tenía voltereta.",
    ],
    "sentence-list-the-brook-swelled-with-crib": ["Arroyo se llenó con cuna."],
    "sentence-list-the-brook-swelled-with-rain": [
      "Arroyo se llenó con lluvia.",
    ],
    "sentence-list-the-brownie-is-very-bird": ["El brownie es muy pájaro."],
    "sentence-list-the-brownie-is-very-delicious": [
      "El brownie es muy delicioso.",
    ],
    "sentence-list-the-carpet-covers-the-floor": ["La alfombra cubre el piso."],
    "sentence-list-the-carpet-covers-the-orbit": [
      "La alfombra cubre el orbita.",
    ],
    "sentence-list-the-cat-scratched-my-advice": [
      "El gato rasguñó mi consejo.",
    ],
    "sentence-list-the-cat-scratched-my-hand": ["El gato rasguñó mi mano."],
    "sentence-list-the-cheerleaders-yelled-the-cheers": [
      "Las porristas gritaron las aclamaciones.",
    ],
    "sentence-list-the-cheerleaders-yelled-the-sled": [
      "Las porristas gritaron las trineo.",
    ],
    "sentence-list-the-clam-held-a-climb": ["La almeja posee una escalar."],
    "sentence-list-the-clam-held-a-pearl": ["La almeja posee una perla."],
    "sentence-list-the-climbers-reached-the-astronomy": [
      "Los escaladores alcanzaron la astronomía.",
    ],
    "sentence-list-the-climbers-reached-the-peak": [
      "Los escaladores alcanzaron la cima.",
    ],
    "sentence-list-the-comedians-told-funny-icicle": [
      "Los comediantes dijeron bromas carámbano.",
    ],
    "sentence-list-the-comedians-told-funny-jokes": [
      "Los comediantes dijeron bromas graciosas.",
    ],
    "sentence-list-the-cool-breeze-felt-banana": [
      "La brisa fresca sentía banana.",
    ],
    "sentence-list-the-cool-breeze-felt-refreshing": [
      "La brisa fresca sentía refrescante.",
    ],
    "sentence-list-the-crew-sailed-the-acorn": [
      "La tripulación del buque bellota.",
    ],
    "sentence-list-the-crew-sailed-the-ship": [
      "La tripulación del buque navegó.",
    ],
    "sentence-list-the-crutches-helped-him-volcano": [
      "Las muletas ayudaron a volcán.",
    ],
    "sentence-list-the-crutches-helped-him-walk": [
      "Las muletas ayudaron a caminar.",
    ],
    "sentence-list-the-driver-witnessed-the-crash": [
      "Conductor fue testigo del accidente.",
    ],
    "sentence-list-the-driver-witnessed-the-kitchen": [
      "Conductor fue testigo del cocina.",
    ],
    "sentence-list-the-earthquake-shook-the-ground": [
      "El terremoto sacudió la tierra.",
    ],
    "sentence-list-the-earthquake-shook-the-virtue": [
      "El terremoto sacudió la virtud.",
    ],
    "sentence-list-the-earthworm-loosens-the-dirt": [
      "La lombriz afloja la suciedad.",
    ],
    "sentence-list-the-earthworm-loosens-the-sunset": [
      "La lombriz afloja la atardecer.",
    ],
    "sentence-list-the-fire-alarm-rang-loudly": ["La alarma sonó con fuerza."],
    "sentence-list-the-fire-alarm-rang-paint": ["La alarma sonó con pintura."],
    "sentence-list-the-fireworks-exploded-in-screwdriver": [
      "Fuegos artificiales estallaron al destornillador.",
    ],
    "sentence-list-the-fireworks-exploded-in-unison": [
      "Fuegos artificiales estallaron al unísono.",
    ],
    "sentence-list-the-football-flew-fifty-broadcast": [
      "El balón voló cincuenta emisión.",
    ],
    "sentence-list-the-football-flew-fifty-yards": [
      "El balón voló cincuenta yardas.",
    ],
    "sentence-list-the-footprint-was-a-clue": ["La huella era una pista."],
    "sentence-list-the-footprint-was-a-tomorrow": ["La huella era una mañana."],
    "sentence-list-the-handwriting-was-in-cursive": [
      "La letra era en cursiva.",
    ],
    "sentence-list-the-handwriting-was-in-daughter": ["La letra era en hija."],
    "sentence-list-the-hen-laid-an-egg": ["La gallina puso un huevo."],
    "sentence-list-the-hen-laid-an-igloo": ["La gallina puso un iglú."],
    "sentence-list-the-horse-pulled-a-bedtime": [
      "El caballo tiró la acostarse.",
    ],
    "sentence-list-the-horse-pulled-a-cart": ["El caballo tiró la carreta."],
    "sentence-list-the-jar-is-closed-geese": ["El frasco se cierra gansos."],
    "sentence-list-the-jar-is-closed-tightly": [
      "El frasco se cierra herméticamente.",
    ],
    "sentence-list-the-king-wore-a-crown": ["El rey llevaba una corona."],
    "sentence-list-the-king-wore-a-hallway": ["El rey llevaba una pasillo."],
    "sentence-list-the-maid-cleaned-the-algebra": [
      "La sirvienta limpió la algebra.",
    ],
    "sentence-list-the-maid-cleaned-the-house": [
      "La sirvienta limpió la casa.",
    ],
    "sentence-list-the-number-zero-equals-none": ["Cero es igual a nada."],
    "sentence-list-the-number-zero-equals-zebra": ["Cero es igual a cebra."],
    "sentence-list-the-opposite-of-sad-is-happy": [
      "Opuesto de tristeza es felicidad.",
    ],
    "sentence-list-the-opposite-of-sad-is-leather": [
      "Opuesto de tristeza es cuero.",
    ],
    "sentence-list-the-pelican-caught-a-city": [
      "El pelícano atrapó un ciudad.",
    ],
    "sentence-list-the-pelican-caught-a-fish": [
      "El pelícano atrapó un pescado.",
    ],
    "sentence-list-the-puppy-wagged-his-pigpen": [
      "El cachorro movió su pocilga.",
    ],
    "sentence-list-the-puppy-wagged-his-tail": ["El cachorro movió su cola."],
    "sentence-list-the-questions-were-answered-correctly": [
      "Las preguntas fueron contestadas correctamente.",
    ],
    "sentence-list-the-questions-were-answered-lamb": [
      "Las preguntas fueron contestadas cordero.",
    ],
    "sentence-list-the-rain-soaked-the-crowd": [
      "La lluvia empapó la multitud.",
    ],
    "sentence-list-the-rain-soaked-the-exhale": [
      "La lluvia empapó la exhalar.",
    ],
    "sentence-list-the-room-had-a-fireplace": [
      "La habitación tenía una chimenea.",
    ],
    "sentence-list-the-room-had-a-voyage": ["La habitación tenía una viaje."],
    "sentence-list-the-sleeping-child-was-chimney": [
      "El niño dormido estaba chimenea.",
    ],
    "sentence-list-the-sleeping-child-was-quiet": [
      "El niño dormido estaba tranquilo.",
    ],
    "sentence-list-the-squirrels-stored-their-bedtime": [
      "Las ardillas almacenan sus acostarse.",
    ],
    "sentence-list-the-squirrels-stored-their-nuts": [
      "Las ardillas almacenan sus nueces.",
    ],
    "sentence-list-the-sun-always-shines-brightly": [
      "El sol siempre brilla intensamente.",
    ],
    "sentence-list-the-sun-always-shines-hamster": [
      "El sol siempre brilla hamster.",
    ],
    "sentence-list-the-sunshine-made-me-hot": ["El sol me hizo calentar."],
    "sentence-list-the-sunshine-made-me-sofa": ["El sol me hizo sofá."],
    "sentence-list-the-thunder-was-very-loud": ["El trueno fue muy ruidoso."],
    "sentence-list-the-thunder-was-very-monkey": ["El trueno fue muy mono."],
    "sentence-list-the-tornado-brought-strong-popcorn": [
      "El tornado trajo fuertes palomitas.",
    ],
    "sentence-list-the-tornado-brought-strong-winds": [
      "El tornado trajo fuertes vientos.",
    ],
    "sentence-list-the-wind-blew-the-leaves": ["El viento sopló las hojas."],
    "sentence-list-the-wind-blew-the-wonder": ["El viento sopló las asombro."],
    "sentence-list-the-winning-racehorse-ran-fast": [
      "El caballo ganador corrió rápido.",
    ],
    "sentence-list-the-winning-racehorse-ran-raincoat": [
      "El caballo ganador corrió impermeable.",
    ],
    "sentence-list-the-woman-wore-red-lipstick": ["La mujer usó labial rojo."],
    "sentence-list-the-woman-wore-red-quicksand": [
      "La mujer usó labial arena.",
    ],
    "sentence-list-they-swam-across-the-bake": ["Ellos nadaron en la hornear."],
    "sentence-list-they-swam-across-the-pool": ["Ellos nadaron en la piscina."],
    "sentence-list-they-will-wake-at-dawn": [
      "Ellos se despiertan al amanecer.",
    ],
    "sentence-list-they-will-wake-at-neck": ["Ellos se despiertan al cuello."],
    "sentence-list-tires-are-made-of-planets": [
      "Los neumáticos son de planetas.",
    ],
    "sentence-list-tires-are-made-of-rubber": ["Los neumáticos son de goma."],
    "sentence-list-use-the-door-to-enter": ["Use la puerta para entrar."],
    "sentence-list-use-the-door-to-thirsty": ["Use la puerta para sediento."],
    "sentence-list-we-chew-with-our-hands": [
      "Nosotros masticamos con nuestros manos.",
    ],
    "sentence-list-we-chew-with-our-teeth": [
      "Nosotros masticamos con nuestros dientes.",
    ],
    "sentence-list-we-drove-on-the-cupcake": [
      "Nosotros conducimos por la magdalena.",
    ],
    "sentence-list-we-drove-on-the-highway": [
      "Nosotros conducimos por la autopista.",
    ],
    "sentence-list-we-eat-lunch-at-noon": ["Comemos el almuerzo al mediodía."],
    "sentence-list-we-eat-lunch-at-ponytail": ["Comemos el almuerzo al cola."],
    "sentence-list-we-feel-with-our-eyes": [
      "Nosotros sentimos con nuestras ojos.",
    ],
    "sentence-list-we-feel-with-our-hands": [
      "Nosotros sentimos con nuestras manos.",
    ],
    "sentence-list-we-flew-a-kite-cry": ["Nosotros volamos una cometa llorar."],
    "sentence-list-we-flew-a-kite-today": ["Nosotros volamos una cometa hoy."],
    "sentence-list-we-hear-with-our-ears": [
      "Nosotros oímos con nuestros oídos.",
    ],
    "sentence-list-we-hear-with-our-feet": [
      "Nosotros oímos con nuestros pies.",
    ],
    "sentence-list-we-see-through-our-eyes": [
      "Nosotros vemos por nuestros ojos.",
    ],
    "sentence-list-we-see-through-our-mouth": [
      "Nosotros vemos por nuestros boca.",
    ],
    "sentence-list-we-slept-in-our-dumbbell": [
      "Nosotros dormimos en nuestra mancuerna.",
    ],
    "sentence-list-we-slept-in-our-tent": [
      "Nosotros dormimos en nuestra carpa.",
    ],
    "sentence-list-we-speak-with-our-ears": [
      "Nosotros hablamos con la orejas.",
    ],
    "sentence-list-we-speak-with-our-mouth": ["Nosotros hablamos con la boca."],
    "sentence-list-we-swam-in-the-afternoon": ["Nosotros nadamos en la tarde."],
    "sentence-list-we-swam-in-the-telephone": [
      "Nosotros nadamos en la teléfono.",
    ],
    "sentence-list-we-walk-on-our-feet": [
      "Nosotros caminamos sobre nuestros pies.",
    ],
    "sentence-list-we-walk-on-our-teeth": [
      "Nosotros caminamos sobre nuestros dientes.",
    ],
    "sentence-list-wear-tuxedos-to-formal-events": [
      "Use trajes para eventos formales.",
    ],
    "sentence-list-wear-tuxedos-to-formal-oatmeal": [
      "Use trajes para eventos avena.",
    ],
    "sentence-list-your-eyes-are-bloodshot-red": [
      "Sus ojos están muy enrojecidos.",
    ],
    "sentence-list-your-eyes-are-bloodshot-seashore": [
      "Sus ojos están muy costa.",
    ],
    "sentence-list-zebras-are-black-and-butter": [
      "Cebras son blanco y mantequilla.",
    ],
    "sentence-list-zebras-are-black-and-white": ["Cebras son blanco y negro."],
    "sentence-title": ["Frases"],
    "sign-in-button-label": ["Entrar"],
    "start-test-button-label": ["Empezar Prueba"],
    "stop-or-go-go": ["Siga"],
    "stop-or-go-instructions-by-color-except-message": ["Excepto Rojo"],
    "stop-or-go-instructions-by-color-message-1": [
      "Presione la barra espaciadora <strong>UNA VEZ</strong> si la palabra es presentada en <strong>AZUL</strong> (sin importar que palabra sea).",
    ],
    "stop-or-go-instructions-by-color-message-2": [
      "<strong>*NO*</strong> presione la barra espaciadora si la palabra es presentada en <strong>ROJO</strong> (sin importar que palabra sea).",
    ],
    "stop-or-go-instructions-by-word-except-message": ["Excepto Pare"],
    "stop-or-go-instructions-by-word-message-1": [
      'Presione la barra espaciadora <strong>UNA VEZ</strong> si la palabra <strong>"SIGA"</strong> aparece.',
    ],
    "stop-or-go-instructions-by-word-message-2": [
      '<strong>*NO*</strong> presione la barra espaciadora si la palabra <strong>"PARE"</strong> aparece.',
    ],
    "stop-or-go-instructions-message-1": [
      'En esta prueba, las palabras <strong>"PARE"</strong> o <strong>"Siga"</strong> estaran presente una a la vez.',
    ],
    "stop-or-go-instructions-message-2": [
      "Responda lo mas rápido y preciso posible.",
    ],
    "stop-or-go-instructions-message-3": [
      "Presione la barra espaciadora para comenzar la tarea...",
    ],
    "stop-or-go-stop": ["Pare"],
    "stop-or-go-title": ["Pare o Siga"],
    "tapping-instructions-message-1": [
      "Presione la barra espaciadora tan rápido como sea posible de forma continua durante 30 segundos.",
    ],
    "tapping-instructions-message-2": [
      "No mantenga presionada la barra espaciadora. Utilize <strong>SOLAMENTE</strong> su mano <strong>derecha</strong> si es derecho, o su mano <strong>izquierda</strong> si es zurdo.",
    ],
    "tapping-instructions-message-3": [
      "Presione la barra espaciadora una vez para comenzar.",
    ],
    "tapping-instructions-message-4": ["Tendrá 3 segundos para prepararse."],
    "tapping-instructions-start-task-message-1": [
      "La prueba empieza en [[time]] segundos.",
    ],
    "tapping-title": ["Tecleando"],
    "task-completed-message-1": [
      "Tarea terminada. Las nuevas instrucciones se mostrarán en breve...",
    ],
    "task-completed-title": ["Tarea Completada"],
    "time-estimation-instructions-image-word": ["10 segundos"],
    "time-estimation-instructions-message-1": [
      "En esta prueba , varios períodos de tiempo ( en segundos ) se presentarán como objetivos.",
    ],
    "time-estimation-instructions-message-2": [
      "Para cada objetivo, presione la barra espaciadora <strong>UNA VEZ</strong> para comenzar y luego una vez después de que se sienta que se ha alcanzado el tiempo objetivo . No utilice un reloj. ",
    ],
    "time-estimation-instructions-message-3": [
      "Responda con la mayor precisión posible.",
    ],
    "time-estimation-instructions-message-4": [
      "Presione la barra espaciadora para comenzar la tarea...",
    ],
    "time-estimation-instructions-start-task-message-1": [
      "Presione la barra espaciadora una vez para comenzar.",
    ],
    "time-estimation-instructions-start-task-message-2": [
      "Presione la barra espaciadora de nuevo después de [[time]] segundos.",
    ],
    "time-estimation-title": ["Tiempo estimado"],
    title: ["Español"],
    "updated-at": [{ _: "2016-11-17T11:48:46-06:00", $: { type: "dateTime" } }],
    "word-general-instructions-message-1": [
      "Responda lo mas rápido y preciso posible.",
    ],
    "word-general-instructions-message-2": [
      "Presione la tecla de flecha <strong>derecha</strong> una vez para empezar.",
    ],
    "word-instructions-image-word-left": ["batalla"],
    "word-instructions-image-word-list": ["asistir,agua,batalla"],
    "word-instructions-image-word-right": ["referee"],
    "word-instructions-message-tap-left": [
      "Presione <strong>UNA VEZ</strong> la tecla de flecha <strong>izquierda</strong> si la palabra de la <strong>izquierda</strong> estaba en la lista.",
    ],
    "word-instructions-message-tap-right": [
      "Presione <strong>UNA VEZ</strong> la tecla de flecha <strong>derecha</strong> si la palabra de la <strong>derecha</strong> estaba en la lista.",
    ],
    "word-list-achieve": ["lograr"],
    "word-list-across": ["alrededor"],
    "word-list-action": ["acción"],
    "word-list-activity": ["actividad"],
    "word-list-advantage": ["ventaja"],
    "word-list-against": ["en contra"],
    "word-list-aggressive": ["agresivo"],
    "word-list-ahead": ["adelante"],
    "word-list-angry": ["enojado"],
    "word-list-appreciate": ["apreciar"],
    "word-list-argue": ["discutir"],
    "word-list-athlete": ["atleta"],
    "word-list-attack": ["atacar"],
    "word-list-attend": ["atender"],
    "word-list-audible": ["audible"],
    "word-list-avoid": ["evitar"],
    "word-list-award": ["premio"],
    "word-list-baseball": ["béisbol"],
    "word-list-basic": ["básico"],
    "word-list-basketball": ["baloncesto"],
    "word-list-battle": ["batalla"],
    "word-list-beginner": ["principiante"],
    "word-list-believe": ["creer"],
    "word-list-block": ["bloquear"],
    "word-list-board": ["tablero"],
    "word-list-boundary": ["límite"],
    "word-list-brave": ["valiente"],
    "word-list-breathe": ["respirar"],
    "word-list-burst": ["ráfaga"],
    "word-list-calm": ["calma"],
    "word-list-charge": ["cargar"],
    "word-list-chase": ["persecución"],
    "word-list-circle": ["círculo"],
    "word-list-climb": ["escalada"],
    "word-list-clinch": ["asegurar"],
    "word-list-close": ["cerca"],
    "word-list-coach": ["entrenador"],
    "word-list-competition": ["competencia"],
    "word-list-condition": ["condición"],
    "word-list-conscious": ["consciente"],
    "word-list-control": ["control"],
    "word-list-crash": ["choque"],
    "word-list-crowd": ["multitud"],
    "word-list-crush": ["aplastar"],
    "word-list-defeat": ["derrota"],
    "word-list-defense": ["defensa"],
    "word-list-destroy": ["destruir"],
    "word-list-direction": ["dirección"],
    "word-list-display": ["monitor"],
    "word-list-distance": ["distancia"],
    "word-list-division": ["división"],
    "word-list-doctor": ["doctor"],
    "word-list-dominant": ["dominante"],
    "word-list-eager": ["ansioso"],
    "word-list-encourage": ["alentar"],
    "word-list-engage": ["contratar"],
    "word-list-enormous": ["enorme"],
    "word-list-enough": ["suficiente"],
    "word-list-entertain": ["entretener"],
    "word-list-equal": ["igual"],
    "word-list-excel": ["sobresalir"],
    "word-list-exercise": ["ejercicio"],
    "word-list-exert": ["ejercer"],
    "word-list-exhale": ["exhalar"],
    "word-list-exhibit": ["exposición"],
    "word-list-expert": ["experto"],
    "word-list-explode": ["explotar"],
    "word-list-extra": ["extra"],
    "word-list-facility": ["instalaciones"],
    "word-list-famous": ["famoso"],
    "word-list-fearless": ["audaz"],
    "word-list-fell": ["cayó"],
    "word-list-flag": ["bandera"],
    "word-list-football": ["fútbol"],
    "word-list-fundamental": ["fundamental"],
    "word-list-furious": ["furioso"],
    "word-list-game": ["juego"],
    "word-list-giant": ["gigante"],
    "word-list-goal": ["gol"],
    "word-list-grind": ["moler"],
    "word-list-group": ["grupo"],
    "word-list-guard": ["guardia"],
    "word-list-gym": ["gimnasio"],
    "word-list-handle": ["manipular"],
    "word-list-health": ["salud"],
    "word-list-heart": ["corazón"],
    "word-list-helmet": ["casco"],
    "word-list-home": ["hogar"],
    "word-list-imbalance": ["desequilibrio"],
    "word-list-immovable": ["inamovible"],
    "word-list-impress": ["impresionar"],
    "word-list-inches": ["pulgadas"],
    "word-list-increase": ["incrementar"],
    "word-list-inside": ["dentro"],
    "word-list-jaw": ["mandíbula"],
    "word-list-join": ["unirse"],
    "word-list-judge": ["juzgar"],
    "word-list-jump": ["saltar"],
    "word-list-kick": ["patear"],
    "word-list-last": ["último"],
    "word-list-lead": ["dirigir"],
    "word-list-level": ["nivel"],
    "word-list-line": ["linea"],
    "word-list-loud": ["ruidoso"],
    "word-list-manage": ["manejar"],
    "word-list-match": ["partido"],
    "word-list-men": ["hombres"],
    "word-list-minor": ["menor"],
    "word-list-move": ["mover"],
    "word-list-name": ["nombre"],
    "word-list-noise": ["ruido"],
    "word-list-number": ["número"],
    "word-list-official": ["oficial"],
    "word-list-option": ["opción"],
    "word-list-overrule": ["anular"],
    "word-list-peak": ["pico"],
    "word-list-people": ["personas"],
    "word-list-performance": ["rendimiento"],
    "word-list-plan": ["plan"],
    "word-list-player": ["jugador"],
    "word-list-position": ["posición"],
    "word-list-positive": ["positivo"],
    "word-list-possible": ["posible"],
    "word-list-predict": ["predecir"],
    "word-list-propel": ["impulsar"],
    "word-list-protect": ["proteger"],
    "word-list-provide": ["proporcionar"],
    "word-list-punish": ["castigar"],
    "word-list-pursue": ["perseguir"],
    "word-list-quick": ["rápido"],
    "word-list-quit": ["Renunciar"],
    "word-list-race": ["raza"],
    "word-list-receive": ["recibir"],
    "word-list-recover": ["recuperar"],
    "word-list-reduce": ["reducir"],
    "word-list-referee": ["árbitro"],
    "word-list-release": ["lanzamiento"],
    "word-list-reply": ["respuesta"],
    "word-list-requirement": ["requisito"],
    "word-list-retrieve": ["recuperar"],
    "word-list-return": ["regresar"],
    "word-list-reward": ["recompensa"],
    "word-list-rough": ["áspero"],
    "word-list-route": ["ruta"],
    "word-list-score": ["puntuación"],
    "word-list-season": ["temporada"],
    "word-list-sideways": ["lateral"],
    "word-list-skate": ["patinar"],
    "word-list-skill": ["habilidad"],
    "word-list-soccer": ["fútbol"],
    "word-list-somersault": ["voltereta"],
    "word-list-speedy": ["rápido"],
    "word-list-sport": ["deporte"],
    "word-list-stadium": ["estadio"],
    "word-list-start": ["empezar"],
    "word-list-steal": ["robar"],
    "word-list-steam": ["vapor"],
    "word-list-stretch": ["estirar"],
    "word-list-strong": ["fuerte"],
    "word-list-sweat": ["sudor"],
    "word-list-swift": ["rápido"],
    "word-list-swim": ["nadar"],
    "word-list-target": ["objetivo"],
    "word-list-taunt": ["pulla"],
    "word-list-team": ["equipo"],
    "word-list-technical": ["técnico"],
    "word-list-temper": ["moderar"],
    "word-list-territory": ["territorio"],
    "word-list-thirsty": ["sediento"],
    "word-list-thrill": ["emoción"],
    "word-list-throw": ["lanzar"],
    "word-list-ticket": ["boleto"],
    "word-list-time": ["tiempo"],
    "word-list-total": ["total"],
    "word-list-touch": ["tocar"],
    "word-list-tough": ["difícil"],
    "word-list-toward": ["hacia"],
    "word-list-track": ["pista"],
    "word-list-training": ["entrenamiento"],
    "word-list-treatment": ["tratamiento"],
    "word-list-trouble": ["problema"],
    "word-list-twist": ["giro"],
    "word-list-umpire": ["árbitro"],
    "word-list-underdog": ["desvalido"],
    "word-list-unfair": ["injusto"],
    "word-list-use": ["usar"],
    "word-list-valiant": ["valiente"],
    "word-list-victory": ["victoria"],
    "word-list-visitor": ["visitante"],
    "word-list-volleyball": ["voleibol"],
    "word-list-walk": ["caminar"],
    "word-list-water": ["agua"],
    "word-list-weak": ["débiles"],
    "word-list-win": ["ganar"],
    "word-list-women": ["mujeres"],
    "word-list-work": ["trabajo"],
    "word-list-yard": ["patio"],
    "word-list-year": ["año"],
    "word-list-zero": ["cero"],
    "word-list-zone": ["zona"],
    "word-title": ["Palabra (Iteración [[index]])"],
    "word-with-list-message-1": [
      "En esta prueba, se presentará una lista de palabras de una palabra a la vez...",
    ],
    "word-with-list-message-2": [
      "Después de presentar la lista , se presentarán pares de palabras. Una de las dos palabras es de la lista.",
    ],
    "word-with-pairs-message-1": [
      "En esta prueba se presentarán pares de palabras. Una de las dos palabras en cada par es de la lista presentada anteriormente durante las pruebas.",
    ],
  };

  return lanQueryParam == "es" ? es_strings : en_strings;
};

export default language;
