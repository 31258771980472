import ReactDOM from 'react-dom/client';
import Root from './containers/root';
import { additionalMiddleware, additionalEnhancers } from './store/configure';
import { FULLSCREEN_SUPPORTED } from './util/browser-support';
import Button from './components/button';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import filter from 'redux-storage-decorator-filter';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers/index';
import thunk from 'redux-thunk';

const reducer = storage.reducer(rootReducer);
const engine = filter(createEngine('xlntbrain-cog-reports'), ['reports']);
const storageMiddleware = storage.createMiddleware(engine);

const middleware = applyMiddleware(
  thunk,
  storageMiddleware,
  ...additionalMiddleware
);
const enhancer = compose(middleware, ...additionalEnhancers);
const store = createStore(reducer, enhancer);
const load = storage.createLoader(engine);
load(store);

const CogTest = () => <Root store={store} />;
const FullScreen = () => {
  const openMaximized = () => {
    window.open(
      window.location.href,
      '_blank',
      [
        'fullscreen=yes',
        'left=1',
        'top=1',
        `width=${window.screen.availWidth}`,
        `height=${window.screen.availHeight}`,
        'scrollbars=yes',
      ].join(',')
    );
  };
  return (
    <div>
      <p>
        In order to provide the proper testing environment, we need to open a
        new, maximized window. Please click the button below.
      </p>
      <Button onClick={openMaximized}>Open In a New Window</Button>
    </div>
  );
};

const cogTestRoot = ReactDOM.createRoot(document.getElementById('cog-test'));
cogTestRoot.render(
  FULLSCREEN_SUPPORTED || window.opener ? <CogTest /> : <FullScreen />
);

const BuildVersion = () => {
  return <span>{BUILD_VERSION}</span>;
};

const cogTestVersionRoot = ReactDOM.createRoot(
  document.getElementById('cog-test-version')
);
cogTestVersionRoot.render(<BuildVersion />);

const ServerUrl = () => {
  var serverURL;
  if (__DEV__) {
    serverURL = 'http://staging.xlntbrain.com/?environment=development';
  } else if (__STAGING__) {
    serverURL = 'https://staging.xlntbrain.com/';
  } else if (__PRODUCTION__) {
    serverURL = 'https://app.xlntbrain.com/';
  }

  return <span>{serverURL}</span>;
};

const xlntbrainServerRoot = ReactDOM.createRoot(
  document.getElementById('xlntbrain-server')
);
xlntbrainServerRoot.render(<ServerUrl />);
