import * as api from "../constants/api";

const Sentry = window.Sentry;

function checkStatus(response) {
  if (
    response.status >= api.HTTP_RESPONSE.MIN &&
    response.status < api.HTTP_RESPONSE.MAX
  ) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(
      new Error(`${response.status}: ${response.statusText}`)
    );
  }
}

function doRequest(method, url, params) {
  const query = Array.from(params)
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");
  const protocol = api.URL.PROTOCOL;
  const host = api.URL.BASE;
  const port = api.URL.PORT;
  return fetch(`${protocol}://${host}:${port}/${url}`, {
    method: method,
    body: query,
    headers: new Headers({
      "Content-type": "application/x-www-form-urlencoded",
    }),
  }).then((response) => {
    if (!response.ok) {
      Sentry.captureMessage(`XML Report Submit request failed ${response.status}: ${response.statusText}`);
      Sentry.captureMessage(`Failed response body: ${response.text()}`);
      throw Error(`${response.status}: ${response.statusText}`);
    }
    return response;
  }).catch((error) => {
    Sentry.captureException(error);
    throw Error(error);
  });
}

function doPost(endpoint, data) {
  return doRequest(api.HTTP_METHOD.POST, endpoint, data);
}

export const doAuthorization = (login, password) => {
  try {
    Sentry.setUser({ email: login });
  } catch (e) {
    console.log("Sentry is not loaded", e);
  }
  const securityTag = "cns";
  const data = [
    [api.FORM_FIELDS.LOGIN, login],
    [api.FORM_FIELDS.PASSWORD, password],
    [api.FORM_FIELDS.SECURITYTAG, securityTag],
  ];
  return doPost(api.URL.ENDPOINTS.AUTHORIZATION, data);
};

export const doAuthorizationWithToken = (userToken) => {
  try {
    Sentry.setUser({ username: userToken });
  } catch (e) {
    console.log("Sentry is not loaded", e);
  }
  const protocol = api.URL.PROTOCOL;
  const host = api.URL.BASE;
  const port = api.URL.PORT;
  
  return fetch(`${protocol}://${host}:${port}/${api.URL.ENDPOINTS.AUTHORIZATION}`, {
    method: api.HTTP_METHOD.POST,
    body:`authToken=${userToken}`,
    headers: new Headers({
      "Content-type": "application/x-www-form-urlencoded",
    }),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }).catch((error) => {
    Sentry.captureException(error);
    throw Error(error);
  });
};

export const doTestSession = (login, password) => {
  const data = [
    [api.FORM_FIELDS.LOGIN, login],
    [api.FORM_FIELDS.PASSWORD, password],
  ];
  return doPost(api.URL.ENDPOINTS.TEST_SESSION, data);
};

export const doSubmitReport = (username, reference, report) => {
  const securityTag = "cns";
  const data = [
    [api.FORM_FIELDS.LOGIN, username],
    [api.FORM_FIELDS.SECURITYTAG, securityTag],
    [api.FORM_FIELDS.REPORT_XML, report],
    [api.FORM_FIELDS.REPORT_REFERENCE, reference],
  ];
  return doPost(api.URL.ENDPOINTS.REPORT_SUBMISSION, data);
};

export const doGetLanguage = (language_key) => {
  const data = [[api.FORM_FIELDS.LANGUAGE_KEY, language_key]];
  return doPost(api.URL.ENDPOINTS.GET_LANGUAGE, data);
};
